import { createSlice } from '@reduxjs/toolkit';

export const solicitudOperativaSlice = createSlice({
    name: 'solicitudOperativa',
    initialState: {
        solicitudOperativas: [
            // tempEvent
        ],
        getDataForm: [],
        ttr_lista: [],
        tso_lista: [],
        pae_lista: []
    },
    reducers: {
        setSolicitudOperativas: (state, { payload } ) => {
            state.solicitudOperativas = payload;
        },
        upSertSolicitudOperativa: (state, {payload}) => {
            state.solicitudOperativas = payload;
        },
        setSolicitudOperativasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataSolicitudSelects: (state, {payload}) => {
            state.ttr_lista = payload.selects.ttr_lista;
            state.tso_lista = payload.selects.tso_lista;
            state.pae_lista = payload.selects.pae_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setSolicitudOperativas,
    upSertSolicitudOperativa,
    setSolicitudOperativasDataForm,
    setDataSolicitudSelects
} = solicitudOperativaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 