import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../styles/assets/img/img1.jpg";
import notification from "../sharedComponents/data/Notification";
import { useAuthStore } from '../hooks/useAuthStore';
import "../styles/scss/panels/header.scss";
import { useSelector, useDispatch } from 'react-redux';


import CardModal from "../sharedComponents/CardsComponents/CardModal";
import CardComponent from "../sharedComponents/CardsComponents/CardComponent";
import { setPaisEmpresas, } from '../store';
import { Card, Col, Row, Button, Form, Modal, Container } from "react-bootstrap";
import { setShowMenuTrue, setShowMenuFalse } from '../store';

let nombreSistema = "Pay Controller";

export default function Header() {
  const dispatch = useDispatch();
  const { showMenu } = useSelector(state => state.auth); // Aquí puedes acceder al estado global



  const toggleSidebar = (e) => {
    //debugger
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
        dispatch( setShowMenuTrue() );
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  function NotificationList() {
    debugger
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div className={(item.status === "online") ? "avatar online" : "avatar"}>{item.avatar}</div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList}
      </ul>
    );
  }

  const { startLogout, user } = useAuthStore();

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      {/* <div className="col-4 header-system-name">
        {nombreSistema}

      </div> */}
      <div className="me-auto header-system-name">
      {nombreSistema}

      </div>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle >
          {/* <Dropdown.Toggle as={CustomToggle}> */}
          {/* <Dropdown.Toggle> */}
          <div className="avatar online" >
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f" style={{ backgroundColor: '#61CE70 !important' }}>
          <div className="dropdown-menu-body">

            <h5 className="mb-1 text-dark fw-semibold">{user.name}</h5>

            <hr />
            <nav>
            {/* <Link onClick={volverPortal}><i className="ri-home-3-line"></i> Volver al Portal</Link> */}
            </nav>
            <nav>             
              <Link onClick={startLogout}><i className="ri-logout-box-r-line"></i> Cerrar Sesión</Link>
              {/* <Link  as={Link} to="/pages/evaluacionJefaturaIniciadaKpis"><i className="ri-logout-box-r-line"></i> Cerrar Sesión</Link> */}

            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>

    

    </div>

  )
}