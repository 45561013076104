import { createSlice } from '@reduxjs/toolkit';

export const paisSlice = createSlice({
    name: 'pais',
    initialState: {
        paises: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setPaises1: (state, { payload } ) => {
            state.paises = payload;
        },
        upSertPais: (state, {payload}) => {
            state.paises = payload;
        },
        setPaisDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setPaises1,
    upSertPais,
    setPaisDataForm
} = paisSlice.actions;