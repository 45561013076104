import { useDispatch, useSelector } from 'react-redux';
import services from '../../services/servicesTeso';
import { onLogin, onLogout, setBancos, setPaises, setBancoDataForm, upSertBanco, setBancoAsignar, setBancoAsignados, setIdBanco } from '../../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useMensajesStore } from '../Generales_Mensajes_Validaciones/useMensajesStore';
import { useAuthStore } from '../useAuthStore';


export const useCategoryStore = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { SuccessMensaje, SuccessDeleteMensaje, abrirCargar } = useMensajesStore();
    const { checkAuthToken } = useAuthStore();


    let userId = sessionStorage.getItem('uid');



    //Lista la información que se muestra en banco.js
    const list = async () => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            //debugger
            //abrirCargar();
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            //console.log("url:" + services.API.Category.List);
            const { data } = await axios.post(services.API.Category.List, 
             { userId }, {headers: headerAxios});            
            if (data.respuesta === "success") {
                for (var i = 0; i < data.Data.length; i++) {
                    if (data.Data[i].status === 1) {
                        data.Data[i].status = "Activo";
                    } if (data.Data[i].status == 0) {
                        data.Data[i].status = "Inactivo";
                    }
                }
                return data.Data;
                //dispatch(setBancos({ bancos: data.data }));
                //cerrarCargar();
            } else {
                if (data.respuesta_tipo === "warning") {
                    NoPermisoMensaje();
                }
                if (data === "") {
                    NoPermisoMensaje();
                }
            }
        } catch (error) {
            ErrorMensaje();
            console.log("error:" + error);
            throw error; // Puedes propagar el error para manejarlo en el componente
        }
    }

    //Actualiza e inserta bancos. 
    const Upsert = async ({ id, name, description, status }) => {
        debugger

        const token = localStorage.getItem('token');
        if (status == "1" || status == true) {
            status = true;
        } else {
            status = false;
        }
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                        }
            if(id == undefined || id == 0){
                  data  = await axios.post(services.API.Category.Create, { userId, name, description, status }, { headers: headerAxios });
            } else {
                  data  = await axios.post(services.API.Category.Update, { id, name, description }, { headers: headerAxios });
            }
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                console.log("data: " + data);
                SuccessMensaje();
                //dispatch(upSertBanco({ bancos: data.data }));
                navigate('/pages/category');
            }
            if (data.data.respuesta === "warning") {
                Swal.fire(
                    'Data duplicated',
                    'Input another data.',
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    //Get Data to update.
    const getDataUpdate = async (id) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Category.GetData, { id }, { headers: headerAxios });
            if (data.respuesta === "success") {
                //history.pushState('')
                //history.push(`/pages/categoryEdit/${id}`, { categoryData });
                //navigate('/pages/bancoEditar');
                return data.Object
                // navigate('/pages/bancoEditar');
                // dispatch(setBancoDataForm({ getData: data.data.banco }));
                // dispatch(setPaises({ paises: data.data.paises }));

            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

    //Elimina 
    const Delete = async (id) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            const { data } = await axios.post(services.API.Category.Delete, { id }, { headers: headerAxios });
            if (data.respuesta === "success") {
                console.log("data: " + data);
                SuccessDeleteMensaje();
            }
            if (data.respuesta_tipo === "warning") {
                Swal.fire(
                    'Problema con el Banco',
                    data.respuesta_detalle,
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    const getBancoAsignar = async(ban_id) => {   
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
            try {
                const headerAxios ={
                    'content-Type': 'application/json',
                    'Authorization': token
                }
                abrirCargar();
                const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Obtener, {ban_id}, {headers: headerAxios});
                if(data.respuesta_tipo === "success"){
                    dispatch( setBancoDataForm({ getData: data.data.banco }) );
                    dispatch( setBancoAsignar({ comboAsignar: data.data.combo_asignar}) );
                    dispatch( setBancoAsignados({ comboAsignados: data.data.combo_asignados}) );
                    dispatch( setIdBanco({ id: ban_id}) );
                    navigate('/pages/bancoAsignar');
                }
            } catch (error) {
                console.log("error:" + error);
    }
    }
    
      //Agrega asignar usuario a la lista.
      const EventoBancoAsignar = async({ban_id, pai_id}) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
            try {
                const headerAxios ={
                    'content-Type': 'application/json',
                    'Authorization': token
                }
                const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Asignar, { ban_id, pai_id}, {headers: headerAxios});
                if(data.respuesta_tipo === "success"){
                    console.log("data: "+ data);
                    dispatch( setBancoAsignar({ comboAsignar: data.data.combo_asignar}) );
                    dispatch( setBancoAsignados({ comboAsignados: data.data.combo_asignados}) );
                    Swal.fire(
                        'Agregado correctamente',
                        '',
                      )
                }
                if(data.respuesta_tipo === "warning"){
                    Swal.fire(
                        'Dato Duplicado',
                        'Por favor seleccione otra.',
                      )
                }
                if(data.respuesta_tipo === "error"){
                    Swal.fire(
                        'Ha ocurrido un error',
                        'Contacte un administrador.',
                      )
                }
            } catch (error) {
                console.log("error:" + error);
                ErrorMensaje();
            }
    }
    
    const BancoDesasignar = async({bxp_id}) => {
        debugger
        bxp_id = parseInt(bxp_id);
        const token = localStorage.getItem('token');
        checkAuthToken();
            try {
                const headerAxios ={
                    'content-Type': 'application/json',
                    'Authorization': token
                }
                const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Desasignar, { bxp_id }, {headers: headerAxios});
                if(data.respuesta_tipo === "success"){
                    console.log("data: "+ data);
                    dispatch( setBancoAsignar({ comboAsignar: data.data.combo_asignar}) );
                    dispatch( setBancoAsignados({ comboAsignados: data.data.combo_asignados}) );
                    Swal.fire(
                        'Eliminado correctamente',
                        '',
                      )
                }
                if(data.respuesta_tipo === "warning"){
                    Swal.fire(
                        'Ha ocurrido un problema',
                        'Por favor seleccione otra.',
                      )
                }
                if(data.respuesta_tipo === "error"){
                    Swal.fire(
                        'Ha ocurrido un error',
                        'Contacte un administrador.',
                      )
                }
            } catch (error) {
                console.log("error:" + error);
                ErrorMensaje();
            }
    }

    const limpiaBanco = async () => {
        //debugger
        dispatch(setBancoDataForm({ getData: '' }));
    }


    // const checkAuthToken = async () => {

    //     const token2 = sessionStorage.getItem('token');
    //     const token = localStorage.getItem('token');
    //     if (!token && !token2) return dispatch(onLogout());

    //     try {   //Se recuperan los datos sesionStorage porque se pierden al recargar, de esta manera validamos que el token siga vigente y no cierre sesión al refrescar.
    //         const nombre = sessionStorage.getItem('Name');
    //         const moneda = sessionStorage.getItem('payload');
    //         localStorage.setItem('Name', nombre);
    //         localStorage.setItem('payload', moneda);
    //         localStorage.setItem('token', token2);
    //         localStorage.setItem('token-init-date', new Date().getTime());
    //         //dispatch( onLogin({ name: data.usuario, uid: data.usuario.id}) );        
    //         //dispatch(onLogin({ name: nombre, uid: moneda }));
    //     } catch (error) {
    //         localStorage.clear();
    //         dispatch(onLogout());
    //     }
    // }

    const NoPermisoMensaje = async () => {
        Swal.fire(
            'No posee permisos para realizar esta acción.',
            'Contacte un administrador.',
            'question'
        )
    }

    const ErrorMensaje = async () => {
        Swal.fire(
            'Ha ocurrido un error.',
            'Contacte un administrador.',
            'error'
        )
    }

    return {
        //* Métodos
        list,
        Upsert,
        getDataUpdate,
        limpiaBanco,
        Delete,
        EventoBancoAsignar,
        getBancoAsignar,
        BancoDesasignar
    }

}