import React from "react";
import { Button, Table } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import HeaderMobile from "../../layouts/HeaderMobile";
import "../../styles/info.css";

export default function Pricing() {

  // Modificar la función para aceptar el nombre del servicio
  const handleSendWhatsApp = (serviceName) => {
    const phoneNumber = '50661372755'; // Asegúrate de usar el número de teléfono correcto
    const message = encodeURIComponent(`Hola, estoy interesado en adquirir el servicio: ${serviceName}`);
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, '_blank');
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-pricing p-4 p-lg-5" style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <h1 className="pricing-title mb-2 text-center">Elige un precio que se ajuste a tu proyecto.</h1>
        <p className="pricing-text mb-4 text-center">El precio total incluye el precio del artículo y una tarifa para el comprador.</p>

        <Table className="table-pricing" responsive="sm" style={{ width: "100%", overflowX: "auto" }}>
          <thead>
            <tr>
              <th className="w-25"></th>
              <th className="w-19 text-center">
                <span>Servicio Gratuito</span>
                <div className="pricing-price">$0<small className="text-black">/mes</small></div>
              </th>
              <th className="w-19 text-center">
                <span>Servicio Personal</span>
                <div className="pricing-price">$39.99<small className="text-black">/mes</small></div>
              </th>
              <th className="w-19 text-center">
                <span>Servicio Empresarial</span>
                <div className="pricing-price">$49.99<small className="text-black">/mes</small></div>
              </th>
              <th className="w-19 text-center selected">
                <span>Servicios Corporativo</span>
                <div className="pricing-price">$59.99<small className="text-black">/mes</small></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="title">
              <td>Servicios de Notificación</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td className="selected">&nbsp;</td>
            </tr>
            <tr>
              <td>Acceso al sistema</td>
              <td ><i className="ri-check-fill"></i></td>
              <td><i className="ri-check-fill"></i></td>
              <td><i className="ri-check-fill"></i></td>
              <td className="selected"><i className="ri-check-fill"></i></td>
            </tr>
            <tr>
              <td>Cantidad de Mensajes (Whatsapp) <small className="text-black">Mensual</small></td>
              <td ><span>50</span></td>
              <td><span>100</span></td>
              <td><span>200</span></td>
              <td className="selected"><span><strong>500</strong></span></td>
            </tr>
            <tr>
              <td>Soporte Técnico</td>
              <td><span>No Incluido</span></td>
              <td><span>Incluido</span></td>
              <td><span>Incluido</span></td>
              <td className="selected"><span><strong>Incluido</strong></span></td>
            </tr>
            <tr>
              <td>Carga Masiva De Usuarios</td>
              <td><span>No Incluido</span></td>
              <td><span>Incluido</span></td>
              <td><span>Incluido</span></td> 
              <td className="selected"><span><strong>Incluido</strong></span></td>
            </tr>
            <tr>
              <td>Cantidad Máxima Registros Por Módulo:</td>
              <td><span>50</span></td>
              <td><span>Ilimitado</span></td>
              <td><span>Ilimitado</span></td>
              <td className="selected"><span><strong>Ilimitado</strong></span></td>
            </tr>
            <tr>
            <td><Button variant="primary">Elegir Plan:</Button></td>
               
              <td>
                <Button variant="primary" onClick={() => handleSendWhatsApp("Servicio Gratuito")}>Contratar</Button>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleSendWhatsApp("Servicio Personal")}>Contratar</Button>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleSendWhatsApp("Servicio Empresarial")}>Contratar</Button>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleSendWhatsApp("Servicios Corporativo")}>Contratar</Button>
              </td>
            
            </tr>
          </tbody>
        </Table>
      </div>
      {/* <Footer /> */}
    </React.Fragment>
  );
}
