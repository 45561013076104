import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMessageStore } from '../../hooks/Transactions/useMessageStore';


export default function Standard() {

  const [Data, setData] = useState([]);
  const [messageId, setMessageId] = useState(0);
  const [messageText, setMessageText] = useState('');
  const [var_1, setMessageVar1] = useState('');
  const [var_2, setMessageVar2] = useState('');
  const [var_3, setMessageVar3] = useState('');
  const [var_4, setMessageVar4] = useState('');



  const { getDataUpdateMessage, sendMessage, UpsertMessage } = useMessageStore();

  useEffect(() => {
      getData();
  }, []);

  const getData = async () => {
    try {
      //debugger
      setData([]);
      let data = await getDataUpdateMessage();
      setData(data[0] || {});
    } catch (error) {
      console.log("error: " + error);
    }
  };
  const mensajeEjemplo = `Estimado Usuario,

  Le informamos que ha vencido su mensualidad con `+ var_1 +`. Para su conveniencia, puede proceder a realizar el pago a través de Sinpe Móvil al número `+ var_2 +`, a nombre de `+ var_3 +`.
  
  Agradecemos de antemano su puntualidad y preferencia. Si tiene alguna consulta o necesita asistencia adicional, no dude en contactarnos.
  
  Atentamente,
  
  El equipo de `+ var_4 +`
  
  POR FAVOR NO RESPONDER ESTE MENSAJE A ESTE NÚMERO.`;

  useEffect(() => {
    if (Object.keys(Data).length > 0) {
      setMessageId(Data.id || 0);
      setMessageText(Data.message_text || '');
      setMessageVar1(Data.var_1 || '');
      setMessageVar2(Data.var_2 || '');
      setMessageVar3(Data.var_3 || '');
      setMessageVar4(Data.var_4 || '');
    }
  }, [Data]);


  const handleInputChangeId = (event) => {
    const { value } = event.target; // Cambia `id` por `value`
    setMessageId(value); // Almacena el valor primitivo
  };

  const handleInputChangeMessage = (event) => {
    const { value } = event.target; // Cambia `message` por `value`
    setMessageText(value); // Almacena el valor primitivo
  };

  const handleInputChangeVar1 = (event) => {
    const { value } = event.target; // Cambia `message` por `value`
    setMessageVar1(value); // Almacena el valor primitivo
  };

  const handleInputChangeVar2 = (event) => {
    const { value } = event.target; // Cambia `message` por `value`
    setMessageVar2(value); // Almacena el valor primitivo
  };

  const handleInputChangeVar3 = (event) => {
    const { value } = event.target; // Cambia `message` por `value`
    setMessageVar3(value); // Almacena el valor primitivo
  };

  const handleInputChangeVar4 = (event) => {
    const { value } = event.target; // Cambia `message` por `value`
    setMessageVar4(value); // Almacena el valor primitivo
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    debugger
    UpsertMessage({ 
      messageId: messageId, 
      var_1: var_1, 
      var_2: var_2, 
      var_3: var_3, 
      var_4: var_4 });
  };

    // Define la función que deseas llamar al hacer clic en el botón
    const handleNotificationOverdue = () => {
      sendMessage();
    };

  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h1 className="main-title mb-0">Configuración</h1>
            </div>
          </div>
          <Card.Body>
          <Form>
            <Row className="g-4">
              <Form.Control type="hidden" name="messageId" value={messageId} onChange={handleInputChangeId} disabled required />
              <Form.Group as={Col} md="12">
                <h2>Explicación</h2>
                <Form.Label htmlFor="name">
                Esta funcionalidad está diseñada para notificar a los usuarios que se encuentran en estado de <strong>"Pago Vencido"</strong> y en <strong>"Estado Notificado" "NO"</strong> a través de un mensaje de WhatsApp. <br /> Por favor, asegúrate de que las variables están preconfiguradas en la siguiente sección de <strong>"Datos Mensaje"</strong>  antes de usar esta función.
                <br />
                <br />
                NOTA: El mensaje es preconfigurado y no puede ser cambiado desde este menú, en caso de requerir un mensaje o caso especial, por favor contactar con el administrador.
                </Form.Label>
              </Form.Group>
            </Row>
            <hr />
            <div>
              <Col sm="12">
                {/* Cambia el tipo a "button" y agrega el manejador de clics */}
                <Button type="button" variant="success" className="btn-sign" onClick={handleNotificationOverdue}>Enviar Notificacion Vencidos</Button>
              </Col>
            </div>
          </Form>
        </Card.Body>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <h3>Datos Mensaje</h3>
              <Row className="g-4">
                <Form.Control type="hidden"  name="messageId" value={messageId} onChange={handleInputChangeId} disabled required />
                <Form.Group as={Col} md="4">
                <h2></h2>
                  <Form.Label htmlFor="name">Nombre Empresa:</Form.Label>
                  <Form.Control type="text" rows={12} placeholder="Empresa" name="var_1" value={var_1} onChange={handleInputChangeVar1} required />
                </Form.Group>
                
                <Form.Group as={Col} md="4">
                <h2></h2>
                  <Form.Label htmlFor="name">Teléfono Contacto/Pago:</Form.Label>
                  <Form.Control type="text" rows={12} placeholder="Message" name="var_2" value={var_2} onChange={handleInputChangeVar2} required />
                </Form.Group>

                <Form.Group as={Col} md="4">
                <h2></h2>
                  <Form.Label htmlFor="name">Nombre Dueño Teléfono:</Form.Label>
                  <Form.Control type="text" rows={12} placeholder="Message" name="var_3" value={var_3} onChange={handleInputChangeVar3} required />
                </Form.Group>

                <Form.Group as={Col} md="4">
                <h2></h2>
                  <Form.Label htmlFor="name">Nombre Equipo/Empresa:</Form.Label>
                  <Form.Control type="text" rows={12} placeholder="Message" name="var_4" value={var_4} onChange={handleInputChangeVar4} required />
                </Form.Group>
              </Row>
              <hr />
              <div>
                <Col sm="12">
                  <Button type="submit" variant="success" className="btn-sign">Guardar</Button>
                  {/* <Button type="submit" variant="danger" as={Link} to="/pages/customerPayment" className="btn-sign">Cancel</Button> */}
                </Col>
              </div>
            </Form>
            <hr />
            <Row>
              <Form.Group as={Col} md="12">
                
                <h2></h2>
                  <Form.Label htmlFor="name">Ejemplo mensaje Whatsapp:</Form.Label>
                  <Form.Control disabled as="textarea" rows={12} placeholder="Message" name="mensajeEjemplo" value={mensajeEjemplo} onChange={handleInputChangeMessage} required />
                </Form.Group>
              </Row>
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
