import React from "react";
import { useEffect } from 'react';
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthStore, useForm } from '../../hooks';
import Swal from 'sweetalert2';
import logo from '../../styles/assets/img/Logo.jpg';



const loginFormFields = {
  loginUser:    '',
  loginPassword: '',
}

export default function Signin() {
  const { startLogin, errorMessage } = useAuthStore();
  //const { loginUser, errorMessage, startRegister } = useAuthStore();
  const { loginUser, loginPassword, onInputChange:onLoginInputChange } = useForm( loginFormFields );

  const loginSubmit = ( event ) => {
    event.preventDefault();
    startLogin({ email: loginUser, password: loginPassword });
}

  useEffect(() => {
    if ( errorMessage !== undefined ) {
      Swal.fire('Error en la autenticación', errorMessage, 'error');
    }    
  }, [errorMessage])

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.2)' }}> 
        <Card.Header>
        <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto', alignItems: 'center' }} /> {/* Aquí se muestra el logo */}
          {/* <Link to="/" className="header-logo mb-4">Pay Controller</Link> */}
          {/* <Card.Title>Signin</Card.Title> */}
          {/* <Card.Text>Welcome back! Please signin to continue.</Card.Text> */}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={ loginSubmit } style={{ margin: '5%'}}>
            <div className="mb-4">
              <Form.Label style={{ color: '#2796cf', borderRadius: '20%' }} >Usuario *</Form.Label>
              <Form.Control type="text"  placeholder="Usuario" name="loginUser" value={ loginUser } onChange={ onLoginInputChange } />
            </div>
            <div className="mb-4">
            <Form.Label style={{ color: '#2796cf' }} >Correo *</Form.Label>
              {/* <Form.Label className="d-flex justify-content-between">Password <Link to="">Olvidó Contraseña?</Link></Form.Label> */}
              <Form.Control type="password" placeholder="Enter your password" name="loginPassword" value={ loginPassword } onChange={ onLoginInputChange } />
            </div>
            <Button  type="submit" variant="primary" className="btn-sign">Login</Button>
          </Form>
          
        </Card.Body>
        <hr />
        <Card.Footer style={{ color: '#2796cf' }}>
         ¿No tiene cuenta? <Link to="/pages/signup"><strong>Registrarse</strong></Link>
        </Card.Footer>
        <Card.Footer>
        <Card.Title style={{ color: '#2796cf' }}> Powered by JMSOFTCR &copy; 2024</Card.Title>
        </Card.Footer>
      </Card>
    </div>
  )
}