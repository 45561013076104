import { createSlice } from '@reduxjs/toolkit';

export const configuracionPagosSlice = createSlice({
    name: 'configuracionPago',
    initialState: {
        conPagos: [
            // tempEvent
        ],
        getDataForm: [],
        con_lista: [],
        recurrencias: [],
        registros_pago_servicio: [],
        lista_opciones: [],
        indiceRecurrencia: [],
        empresas: [],    
    },
    reducers: {
        setConfiguracionesPagos: (state, { payload } ) => {
            state.conPagos = payload.configuracionPago.con_lista;
            state.registros_pago_servicio = payload.configuracionPago.registros_pago_servicio;
            state.recurrencias = payload.configuracionPago.recurrencias;
            state.lista_opciones = payload;             
        },        
        setConfiguracionesPagos2: (state, { payload } ) => {
            state.conPagos = payload.configuracionPago.con_lista;       
        },       
        upSertConfiguracionesPagos: (state, {payload}) => {
            state.pagosServicios = payload;
        },
        setConfiguracionesPagosDataForm: (state, {payload}) => {
            state.getDataForm = payload;
        }, 
        setEmpresaPagosDataForm: (state, {payload}) => {
            state.empresas = payload.getEmpresa.ese_lista;
        }, 
        setEmpresaPagosDataForm2: (state, {payload}) => {
            state.empresas = payload.getEmpresa;
        },     
        // setRecurrenciasSelects: (state, {payload}) => {
        //     state.recurrencias = payload.selects.configuracionPago.recurrencias;            
        // },
        setOpcionesRecurrenciaSelects: (state, {payload}) => {
            state.lista_opciones = payload.configuracionPago.lista_opciones;               
        },
        setReprocesarRecurrencia: (state, {payload}) => {
            state.registros_pago_servicio = payload.configuracionPago.registros_pago_servicio;           
        },
        actualizarConPagos: (state, { payload } ) => {
            state.conPagos = payload;       
        }, 
    }
});


// Action creators are generated for each case reducer function
export const {
    setConfiguracionesPagos,
    setConfiguracionesPagos2,
    upSertConfiguracionesPagos,
    setConfiguracionesPagosDataForm,   
    setConfiguracionesPagosSelects,
    // setRecurrenciasSelects,
    setOpcionesRecurrenciaSelects,   
    setEmpresaPagosDataForm,
    setEmpresaPagosDataForm2,
    setReprocesarRecurrencia,
    actualizarConPagos,
} = configuracionPagosSlice.actions;