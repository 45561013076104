import { createSlice } from '@reduxjs/toolkit';

export const metodoPagoSlice = createSlice({
    name: 'metodoPago',
    initialState: {
        metodoPagos: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setMetodoPagos: (state, { payload } ) => {
            state.metodoPagos = payload;
        },
        upSertMetodoPago: (state, {payload}) => {
            state.metodoPagos = payload.respuesta.metodos_pago;
        },
        setMetodoPagoDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setMetodoPagos,
    upSertMetodoPago,
    setMetodoPagoDataForm
} = metodoPagoSlice.actions;