import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container, Modal } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useInventoryStore } from '../../hooks/Inventory/useInventoryStore';
import { useProductStore } from '../../hooks/Mantenimiento_Generales/useProductStore';
import Select from 'react-select';
import { Grid, _ } from "gridjs-react";
import { h } from "gridjs";
import Swal from 'sweetalert2';
import { format } from 'date-fns';




export default function Standard() {

  //****************** Estados *******************//
  const [showModal, setShowModal] = useState(false);
  const [Data, setData] = useState([]); // Estado local para almacenar los datos de la lista
  const [suppliers, setSupplier] = useState([]);
  const [warehouses, setWarehouse] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [formData, setFormData] = useState({
    inventoryID: 0,
    transactionDate: format(new Date(), 'yyyy-MM-dd'),
    invoice: '',
    comments: '',
    productIds: '',
    productID: 0,
    name: '',
    price: 0,
    qty: 1,
    tax: 0,
    discount: 0,
    SubTotal: 0,
    tax_apply: 0,
    discount_total: 0,
    total: 0
  });
  //****************** FIN Estados *******************//


  //****************** LLAMADOS APIS *******************//

  const { listDataForm, Upsert } = useInventoryStore();
  const { list } = useProductStore();
  const location = useLocation();
  const inventoryData = location.state ? location.state.inventoryData.Object[0] || {} : {};
  const productData = location.state ? location.state.inventoryData.Object2 || {} : {};

  //****************** FIN LLAMADOS APIS *******************//


  useEffect(() => {
    //debugger
    getListDataForm();
    getList();
    //setSelectedProducts({productData});
    if (productData.length > 0) {
      const products = productData.map(product => ({
        product_id: product.id || product.product_id,
        name: product.name,
        code: product.code,
        code_bar: product.code_bar,
        description: product.description,
        price: product.price,
        qty: product.qty,
        tax: product.tax,
        discount: product.discount,
        Subtotal: product.price * product.qty,
        tax_apply: (product.price * product.tax) / 100,
        discount_total: product.price * product.discount,
        total: product.price * product.qty + (product.price * product.tax) / 100 - product.price * product.discount
      }));
      setSelectedProducts(products);
    }
  }, []);


  const getList = async () => {
    try {
      const data = await list();
      setData(data);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  useEffect(() => {
    //debugger
    //getListDataForm();
    if (Object.keys(inventoryData).length > 0) {
      const transactionDate = inventoryData.transaction_date ?
        new Date(inventoryData.transaction_date).toISOString().split('T')[0] : '';
      //debugger
      setFormData({
        inventoryID: inventoryData.id || 0,
        transactionDate: transactionDate,
        invoice: inventoryData.invoice || '',
        comments: inventoryData.comments || '',
      });

      const supplier = suppliers.find(cat => cat.id === inventoryData.supplier_id);
      const warehouse = warehouses.find(unit => unit.id === inventoryData.warehouse_id
      );

      if (supplier) {
        setSelectedSupplier({ value: supplier.id, label: supplier.name });
      }
      if (warehouse) {
        setSelectedWarehouse({ value: warehouse.id, label: warehouse.name });
      }
    }
  }, [inventoryData, suppliers, warehouses]);

  useEffect(() => {
    getListDataForm();
  }, []);

  const getListDataForm = async () => {
    try {
      const data = await listDataForm();
      setSupplier(data.supplier);
      setWarehouse(data.warehouse);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  //HANDLE TO SAVE NEW INVENTORY
  const handleFormSubmit = (event) => {
    //debugger
    event.preventDefault();
    Upsert({
      inventoryID: formData.inventoryID,
      supplierId: selectedSupplier.value,
      warehouseId: selectedWarehouse.value,
      transactionDate: formData.transactionDate,
      invoice: formData.invoice,
      comments: formData.comments,
      productIds: selectedProducts
      //console.log({id: formData.id, productName: formData.name, categoryId: selectedCategory, unitTypeId: selectedUnitType, productCode: formData.code, productCodeBar: formData.codeBar, productDescription: formData.description, productPrice: formData.price, productTax: formData.tax, productImage: formData.image, productMinQty: formData.minQty});
    });
  }

  const handleSupplierChange = (selectedOption) => {
    setSelectedSupplier(selectedOption);
  };

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);

    setFormData({
      ...formData,
      name: '',
      price: 0,
      qty: 1,
      tax: 0,
      discount: 0,
      SubTotal: 0,
      tax_apply: 0,
      discount_total: 0,
      total: 0
    });


  }

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (name === 'discount_total') {
      const formattedValue = parseFloat(value).toFixed(2);
      setFormData({
        ...formData,
        [name]: formattedValue
      });
    }
  };

  //GET THE INFORMATION 
  const handleGetData = (productId) => {
    //debugger
    const selectedProduct = Data.find(product => product.id === parseInt(productId));
    if (selectedProduct) {
      const taxApply = selectedProduct.price * selectedProduct.tax / 100;
      const SubTotalParcial = parseInt(selectedProduct.price);
      const discountApply = selectedProduct.price * selectedProduct.discount;
      const total = parseFloat(SubTotalParcial + taxApply).toFixed(2);
      setFormData({
        ...formData,
        productID: selectedProduct.id || 0,
        name: selectedProduct.name || '',
        code: selectedProduct.code || '',
        code_bar: selectedProduct.code_bar || '',
        description: selectedProduct.description || '',
        price: selectedProduct.price || 0,
        qty: selectedProduct.qty || 1,
        tax: selectedProduct.tax || 0,
        discount: 0,
        SubTotal: SubTotalParcial || 0,
        tax_apply: taxApply || 0,
        //discount_total: discountApply || 0,
        total: total || 0
      });
      //handleCloseModal();
    }
  };

  const handleEditGetData = (id) => {
    //debugger
    //const selectedProduct = Data.find(product => product.id === parseInt(productId));
    const dataProductModal = selectedProducts.find(product => product.product_id === parseInt(id));
    
    if(dataProductModal){
      const SubTotalParcial = parseInt(dataProductModal.price);
      const taxApply = dataProductModal.price * dataProductModal.tax / 100;
      const total = parseFloat( dataProductModal.qty * (SubTotalParcial + taxApply)).toFixed(2);


      setFormData({
        ...formData,
        productID: dataProductModal.product_id || 0,
        name: dataProductModal.name || '',
        code: dataProductModal.code || '',
        code_bar: dataProductModal.code_bar || '',
        description: dataProductModal.description || '',
        price: dataProductModal.price || 0,
        qty: dataProductModal.qty || 1,
        tax: dataProductModal.tax || 0,
        discount: dataProductModal.discount || 0,
        SubTotal: SubTotalParcial || 0,
        tax_apply: taxApply || 0,
        //discount_total: discountApply || 0,
        total: total || 0
      })
      handleShowModal();
    }
    //const productExists = selectedProducts.some(product => product.product_id === newProduct.product_id);


  }

  //MANAGE THE DISCOUNT PROCESS AND TOTAL WITH DISCOUNT
  const handleDiscount = (event) => {
    const discount = event.target.value;
    const discountTotal = formData.price * discount / 100;
    const formattedValue = parseFloat(discountTotal).toFixed(2);
    const total = formData.qty * ((formData.SubTotal + formData.tax_apply) - formattedValue);
    const totalFormat = parseFloat(total).toFixed(2);
    setFormData({
      ...formData,
      discount: event.target.value,
      discount_total: formattedValue || 0,
      total: totalFormat
    });
  };

  //MANAGE THE QTY PROCESS AND TOTAL
  const handleQTY = (event) => {
    const QTY = parseInt(event.target.value); // Convertir a entero para asegurar operaciones numéricas
    const subtotal = formData.SubTotal; // Subtotal actual
    const taxApply = formData.tax_apply; // Impuesto aplicado actual
    const discountTotal = formData.discount_total; // Descuento total actual
  
    // Calcular el nuevo total basado en la cantidad actualizada
    const newTotal = QTY * (subtotal + taxApply - discountTotal);
  
    // Formatear el total a dos decimales
    const totalFormat = parseFloat(newTotal).toFixed(2);
  
    // Actualizar el estado con el nuevo valor de qty y total calculado
    setFormData({
      ...formData,
      qty: QTY,
      total: totalFormat
    });
  };

  //SAVE THE PRODUCT PREVIUSLY CREATED ON THE SELECT, FINAL STEPS.

  const handleSaveProduct = () => {
    const newProduct = {
      product_id: formData.productID,
      name: formData.name,
      code: formData.code,
      code_bar: formData.code_bar,
      description: formData.description,
      price: formData.price,
      qty: formData.qty,
      tax: formData.tax,
      discount: formData.discount,
      Subtotal: formData.SubTotal,
      tax_apply: formData.tax_apply,
      discount_total: formData.discount_total,
      total: formData.total
    };
  
    // Verificar si el product_id ya existe en selectedProducts
    const productExists = selectedProducts.some(product => product.product_id === newProduct.product_id);
  
    if (productExists) {
      // Mostrar alerta si el producto ya está registrado y preguntar si desea actualizar
      Swal.fire({
        icon: 'warning',
        title: 'Product already registered',
        text: 'The product is already added. Do you want to update the existing product?',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        cancelButtonText: 'No, cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          // Si el usuario confirma, actualizar los datos del producto en el array
          const updatedProducts = selectedProducts.map(product =>
            product.product_id === newProduct.product_id ? newProduct : product
          );
          setSelectedProducts(updatedProducts);
          Swal.fire({
            icon: 'success',
            title: 'Product updated',
            text: 'The product has been updated successfully'
          });
        }
      });
    } else {
      // Si el producto no está registrado, añadirlo a selectedProducts
      setSelectedProducts([...selectedProducts, newProduct]);
    }
    //handleCloseModal(); // Comentar o descomentar según sea necesario
  };

  // const handleSaveProduct = () => {

  //   //debugger
  //   const newProduct = {
  //     product_id: formData.productID,
  //     name: formData.name,
  //     code: formData.code,
  //     code_bar: formData.code_bar,
  //     description: formData.description,
  //     price: formData.price,
  //     qty: formData.qty,
  //     tax: formData.tax,
  //     discount: formData.discount,
  //     Subtotal: formData.SubTotal,
  //     tax_apply: formData.tax_apply,
  //     discount_total: formData.discount_total,
  //     total: formData.total
  //   };

  //   // Verificar si el product_id ya existe en selectedProducts
  //   const productExists = selectedProducts.some(product => product.product_id === newProduct.product_id);

  //   if (productExists) {
  //     // Mostrar alerta si el producto ya está registrado
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Product already registered',
  //       text: 'Cannot register because the product is already added'
  //     });
  //   } else {
  //     // Si el producto no está registrado, añadirlo a selectedProducts
  //     setSelectedProducts([...selectedProducts, newProduct]);
  //     //handleCloseModal(); // Comentar o descomentar según sea necesario
  //   }
  //   //handleCloseModal();
  // };

  const handleDelete = (id) => {
    //debugger
    const filteredProducts = selectedProducts.filter(product => product.product_id !== parseInt(id));
    setSelectedProducts(filteredProducts);
    //const productExists = selectedProducts.some(product => product.product_id === id);

    //alert("ID:" + id);
  }

  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Matenimiento Inventario</h4>
            </div>
          </div>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <Row className="g-4">
                <Form.Control type="hidden" name="inventoryID" value={formData.inventoryID} onChange={handleInputChange} disabled required />

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="transactionDate">Fecha</Form.Label>
                  <Form.Control type="date" placeholder="Date" name="transactionDate" value={formData.transactionDate} onChange={handleInputChange} required max={new Date().toISOString().split("T")[0]} />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="invoice">Número Factura</Form.Label>
                  <Form.Control type="text" placeholder="Invoice" name="invoice" value={formData.invoice} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label>Proveedores</Form.Label>
                  <Select
                    name="categoria"
                    id="id"
                    className="mb-3"
                    placeholder="Buscar"
                    onChange={handleSupplierChange}
                    options={suppliers.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    value={selectedSupplier}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label>Almacenes</Form.Label>
                  <Select
                    name="unitType"
                    id="unitType"
                    className="mb-3"
                    placeholder="Buscar"
                    onChange={handleWarehouseChange}
                    options={warehouses.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    value={selectedWarehouse}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="Comments">Comentarios</Form.Label>
                  <Form.Control type="text" placeholder="comments" name="comments" value={formData.comments} onChange={handleInputChange} required />
                </Form.Group>
              </Row>
              <hr />
              <div>
                <Button variant="success" onClick={handleShowModal}>Nuevo Producto</Button>
              </div>
              <Card.Body>
                <Grid
                  data={selectedProducts || []}
                  columns={[
                    { name: 'product_id', hidden: true },
                    { id: 'name', name: 'Nombre' },
                    { id: 'code', name: 'Codigo' },
                    { id: 'code_bar', name: 'Codigo Barra' },
                    { id: 'description', name: 'Descripcion', hidden: true},
                    { id: 'price', name: 'Precio' },
                    { id: 'qty', name: 'Qty' },
                    { id: 'tax', name: 'Impuesto' },
                    { id: 'discount', name: 'Descuento' },
                    { id: 'Subtotal', name: 'Subtotal' },
                    { id: 'tax_apply', name: 'Impuesto Aplicado' },
                    { id: 'total', name: 'Total' },
                    {
                      name: 'Editar',
                      formatter: (cell, row) => {
                        return h('Button', {
                          className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-warning bg-blue-600',
                          onClick: () => handleEditGetData(`${row.cells[0].data}`)
                        }, 'Editar');
                      }
                    },
                    {
                      name: 'Eliminar',
                      formatter: (cell, row) => {
                        return h('Button', {
                          className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-danger',
                          onClick: () => handleDelete(`${row.cells[0].data}`)
                        }, 'Eliminar');
                      }
                    },
                  ]}
                  search={true}
                  pagination={{
                    enabled: true,
                    limit: 10
                  }}
                  sort={true}
                  resizable={true}
                  language={{
                    search: {
                      placeholder: 'Write to search...',
                    },
                    sort: {
                      sortAsc: 'Ascending column order.',
                      sortDesc: 'Descending column order.',
                    },
                    pagination: {
                      previous: 'Previous',
                      next: 'Next',
                      navigate: (page, pages) => `Page ${page} of ${pages}`,
                      page: (page) => `Page ${page}`,
                      showing: 'Showing of',
                      of: 'of',
                      to: 'to',
                      results: 'records',
                    },
                    loading: 'Loading...',
                    noRecordsFound: 'No matches found.',
                    error: 'An error occurred while obtaining the data.',
                  }}
                  className={{
                    table: 'table table-bordered mb-0'
                  }}
                />
              </Card.Body>
              <hr />
              <div className="text-center">
                <Button type="submit" variant="success" className="me-2">Guardar</Button>
                <Button variant="danger" as={Link} to="/pages/inventory">Cancelar</Button>
              </div>
            </Form>
          </Card.Body>
        </div>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card.Body>
            <Grid
              data={Data}
              columns={[
                { name: 'id', hidden: true },
                { id: 'name', name: 'Nombre' },
                { id: 'price', name: 'Precio' },
                //{ id: 'description', name: 'Description' },
                { id: 'category_name', name: 'Categoria' },
                { id: 'code', name: 'Codigo' },
                { id: 'code_bar', name: 'Codigo Barra' },
                {
                  name: 'Select',
                  formatter: (cell, row) => {
                    return h('Button', {
                      className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-warning bg-blue-600',
                      onClick: () => handleGetData(`${row.cells[0].data}`)
                    }, 'Select');
                  }
                },

              ]}
              search={true}
              pagination={{
                enabled: true,
                limit: 10
              }}
              sort={true}
              resizable={true}
              language={{
                search: {
                  placeholder: 'Write to search...',
                },
                sort: {
                  sortAsc: 'Ascending column order.',
                  sortDesc: 'Descending column order.',
                },
                pagination: {
                  previous: 'Previous',
                  next: 'Next',
                  navigate: (page, pages) => `Page ${page} of ${pages}`,
                  page: (page) => `Page ${page}`,
                  showing: 'Showing of',
                  of: 'of',
                  to: 'to',
                  results: 'records',
                },
                loading: 'Loading...',
                noRecordsFound: 'No matches found.',
                error: 'An error occurred while obtaining the data.',
              }}
              className={{
                table: 'table table-bordered mb-0'
              }}
            />
          </Card.Body>
          {/* Aquí puedes agregar el formulario o contenido que necesites para agregar un producto */}
          <Form>
            <Form.Control type="hidden" name="product_id" value={formData.inventoryID} onChange={handleInputChange} disabled required />

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="name">Nombre Producto</Form.Label>
                <Form.Control type="text" placeholder="name" name="name" value={formData.name} onChange={handleInputChange} required disabled />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="price">Precio</Form.Label>
                <Form.Control type="number" placeholder="price" name="price" value={formData.price} onChange={handleInputChange} required />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="qty">QTY</Form.Label>
                <Form.Control type="number" placeholder="qty" name="qty" value={formData.qty} min={1} onChange={handleQTY} required />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="tax">Impuesto</Form.Label>
                <Form.Control type="number" placeholder="tax" name="tax" value={formData.tax} onChange={handleInputChange} required />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="discount">Descuento</Form.Label>
                <Form.Control type="number" placeholder="discount" name="discount" value={formData.discount} onChange={handleDiscount} required />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="SubTotal">SubTotal</Form.Label>
                <Form.Control type="number" placeholder="SubTotal" name="SubTotal" value={formData.SubTotal} onChange={handleInputChange} required disabled />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="tax_apply">Impuesto Aplicado</Form.Label>
                <Form.Control type="number" placeholder="tax_apply" name="tax_apply" value={formData.tax_apply} onChange={handleInputChange} required disabled />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="discount_total">Descuento Total</Form.Label>
                <Form.Control type="number" placeholder="discount_total" name="discount_total" value={formData.discount_total} onChange={handleInputChange} onBlur={handleBlur} required disabled />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="total">Total</Form.Label>
                <Form.Control type="text" placeholder="total" name="total" value={formData.total} onChange={handleInputChange} required disabled />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleCloseModal}>Cerrar</Button>
          <Button variant="success" onClick={handleSaveProduct}>Agregar Producto</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
