import { createSlice } from '@reduxjs/toolkit';

export const bancoSlice = createSlice({
    name: 'banco',
    initialState: {
        bancos: [
            // tempEvent
        ],
        getDataForm: [],
        paises: [],
        combo_asignar: [],
        combo_asignados: [],
        IdBanco: [],
    },
    reducers: {
        setBancos: (state, { payload } ) => {
            state.bancos = payload;
        },
        setPaises: (state, { payload } ) => {
            state.paises = payload;
        },
        upSertBanco: (state, {payload}) => {
            state.bancos = payload;
        },
        setBancoDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setBancoAsignar: (state, {payload}) => {
            state.combo_asignar = payload.comboAsignar;
        },
        setBancoAsignados: (state, {payload}) => {
            state.combo_asignados = payload.comboAsignados;
        },
        setIdBanco: (state, {payload}) => {
            state.IdBanco = payload.id;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setBancos,
    setPaises,
    upSertBanco,
    setBancoDataForm,
    setBancoAsignar,
    setBancoAsignados,
    setIdBanco
} = bancoSlice.actions;