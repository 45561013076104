import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'checking', // 'authenticated','not-authenticated',
        user: {},
        errorMessage: undefined,
        sistemas: [],
        permisos: [],
        sistemaSelect: {},
        estadoCargando: false,
        paisEmpresas: [],
        showMenu: false,

    },
    reducers: {
        onChecking: ( state ) => {
            state.status = 'checking';
            state.user   = {};
            state.errorMessage = undefined;
        },
        onLogin: ( state, { payload } ) => {
            state.status = 'authenticated';
            state.user = payload;
            state.errorMessage = undefined;
        },
        onLogout: ( state, { payload } ) => {
            state.status = 'not-authenticated';
            state.user   = {};
            state.errorMessage = payload;
        },
        clearErrorMessage: ( state ) => {
            state.errorMessage = undefined;
        },
        setSistemas:(state, {payload})=>{
            state.sistemas= payload;
        },
        setPermisos:(state, {payload})=>{
            state.permisos= payload;
        },
        setEstadoCargandoTrue: ( state ) => {
            state.estadoCargando = true;
        },
        setEstadoCargandoFalse: ( state ) => {
            state.estadoCargando = false;
        },
        setPaisEmpresas: (state, {payload}) =>{
            state.paisEmpresas = payload.data;
        },
        setShowMenuFalse: ( state ) => {
            state.showMenu = false;
        },
        setShowMenuTrue: ( state ) => {
            state.showMenu = true;
        },
    }
});


// Action creators are generated for each case reducer function
export const { onChecking, onLogin, onLogout, clearErrorMessage, setSistemas, setEstadoCargandoTrue, setEstadoCargandoFalse, setPaisEmpresas, setShowMenuFalse, setShowMenuTrue} = authSlice.actions;