import { createSlice } from '@reduxjs/toolkit';

export const pagoXOperativaSlice = createSlice({
    name: 'pagoXOperativa',
    initialState: {
        pagoXOperativas: [
            // tempEvent
        ],
        getDataForm: [],
        tso_lista: [],
        tpa_lista: [],
        pae_lista: []
    },
    reducers: {
        setPagoXOperativas: (state, { payload } ) => {
            state.pagoXOperativas = payload;
        },
        upSertPagoXOperativa: (state, {payload}) => {
            state.pagoXOperativas = payload;
        },
        setPagoXOperativasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataSelects: (state, {payload}) => {
            state.tso_lista = payload.selects.tso_lista;
            state.tpa_lista = payload.selects.tpa_lista;
            state.pae_lista = payload.selects.pae_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setPagoXOperativas,
    upSertPagoXOperativa,
    setPagoXOperativasDataForm,
    setDataSelects
} = pagoXOperativaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 