import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCustomerStore } from '../../hooks/Mantenimiento_Generales/useCustomerStore';
import { useMessageStore } from "../../hooks/Transactions/useMessageStore";
import '../../styles/olas.css';
import '../../styles/botonCentral.css';

export default function Standard() {
  // STATE
  const [data, setData] = useState([]);
  const [dataMessageMonth, setDataMonth] = useState([]);

  // API CALL
  const { list } = useCustomerStore();
  const { getDataUpdateMessageMonth } = useMessageStore();
  
  useEffect(() => {
    const fetchData = async () => {
      await getList();
      const monthData = await getListMonth();
      setDataMonth(monthData || []);
    };

    fetchData();
  }, []);


  const getList = async () => {
    try {
      const result = await list();
      setData(result || []);
    } catch (error) {
      console.log("error: " + error);
    } 
  };

  const getListMonth = async () => {
    try {
      const result = await getDataUpdateMessageMonth();
      console.log("resultMonth:", result); // Verifica el contenido de la respuesta
      return result || [];
    } catch (error) {
      console.log("error: " + error);
      return []; // Asegúrate de devolver un array vacío en caso de error
    } 
  };

  // Memoized calculations
  const totalRecords = useMemo(() => data.length, [data]);
  const paidCount = useMemo(() => data.filter(record => record.statusPayment === 'Paid').length, [data]);
  const overdueCount = useMemo(() => data.filter(record => record.statusPayment === 'Overdue').length, [data]);
  const pendingCount = useMemo(() => data.filter(record => record.statusPayment === 'Pending').length, [data]);

  // Verifica si dataMessageMonth tiene datos y estructura esperada
  const hasData = dataMessageMonth.length > 0;
  const { MensajesDisponibles = 0, MensajesUsados = 0, MensajesRestantes = 0 } = hasData ? dataMessageMonth[0] : {};

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col sm="12" md="6" lg="3" className="mb-4">
          <div className="container">
            <div className="content">
              <h1>Total Usuarios:</h1>
              <p>{`Total: ${totalRecords}`}</p>
            </div>
            <div className="waves">
              <div className="wave circulo a"></div>
              <div className="wave circulo b"></div>
              <div className="wave circulo c"></div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="6" lg="3" className="mb-4">
          <div className="container">
            <div className="content">
              <h1>Usuarios Pagos:</h1>
              <p>{`Total: ${paidCount}`}</p>
            </div>
            <div className="waves">
              <div className="wave circulo a"></div>
              <div className="wave circulo b"></div>
              <div className="wave circulo c"></div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="6" lg="3" className="mb-4">
          <div className="container">
            <div className="content">
              <h1>Usuarios Vencidos:</h1>
              <p>{`Total: ${overdueCount}`}</p>
            </div>
            <div className="waves">
              <div className="wave circulo a"></div>
              <div className="wave circulo b"></div>
              <div className="wave circulo c"></div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="6" lg="3" className="mb-4">
          <div className="container">
            <div className="content">
              <h1>Mensajes:</h1>
              <p>{`Total: ${MensajesDisponibles}`}</p>
              <p>{`Usados: ${MensajesUsados}`}</p>
              <p>{`Disponibles: ${MensajesRestantes}`}</p>
            </div>
            <div className="waves">
              <div className="wave circulo a"></div>
              <div className="wave circulo b"></div>
              <div className="wave circulo c"></div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="buttons-container">
        <div className="button-wrapper">
          <Button variant="info" as={Link} to="/pages/CustomerPayment">Ir a información de usuarios</Button>
        </div>
      </div>
    </React.Fragment>
  );
}
