import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useWarehouseStore } from '../../hooks/Mantenimiento_Generales/useWarehouseStore';


export default function Standard() {
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    location: '',
    capacity: 0,
    code: '',
    status: 1
  });

  const { Upsert } = useWarehouseStore();


  const location = useLocation();
  const warehouseData = location.state ? location.state.warehouseData[0] || {} : {};

  useEffect(() => {
    if (Object.keys(warehouseData).length > 0) {
      setFormData({
        id: warehouseData.id || 0,
        name: warehouseData.name || '',
        location: warehouseData.location || '',
        capacity: warehouseData.capacity || 0, 
        code: warehouseData.Code || '',
        status: warehouseData.status || 1,
      });
    }
  }, [warehouseData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    Upsert({ id: formData.id, name: formData.name, location: formData.location, capacity: formData.capacity, code: formData.code, status: formData.status });
    // Aquí puedes enviar formData a donde lo necesites
    //alert(JSON.stringify(formData));
    //console.log(JSON.stringify(formData));
  };

  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Mantenimiento Almacenes</h4>
            </div>
          </div>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <Row className="g-12">
                <Form.Control type="hidden" name="id" value={formData.id} onChange={handleInputChange} disabled required />
                <Form.Group as={Col}>
                  <Form.Label htmlFor="name">Nombre</Form.Label>
                  <Form.Control type="text" placeholder="Nombre" name="name" value={formData.name} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="location">Locación</Form.Label>
                  <Form.Control type="text" placeholder="Locación" name="location" value={formData.location} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="capacity">Capacidad</Form.Label>
                  <Form.Control type="text" placeholder="Capacidad" name="capacity" value={formData.capacity} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="code">Código</Form.Label>
                  <Form.Control type="text" placeholder="Codigo" name="code" value={formData.code} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col}>
                  <div className="mb-4">
                    <Form.Label>Estado</Form.Label>
                    <Form.Select name="status" value={formData.status} onChange={handleInputChange} aria-label="Default select example" >
                      <option>Select</option>
                      <option value={1}>Activo</option>
                      <option value={0}>Inactivo</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Row>
              <hr />
              <div>
                <Col sm="12">
                  <Button type="submit" variant="success" className="btn-sign">Guardar</Button>
                  <Button type="submit" variant="danger" as={Link} to="/pages/warehouse" className="btn-sign">Cancelar</Button>
                </Col>
              </div>
            </Form>
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
