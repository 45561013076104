import { createSlice } from '@reduxjs/toolkit';

export const empresaSlice = createSlice({
    name: 'empresa',
    initialState: {
        empresas: [
            // tempEvent
        ],
        getDataForm: [],
        combo_asignar: [],
        combo_asignados: [],
        IdEmpresa: [],
    },
    reducers: {
        setEmpresas1: (state, { payload } ) => {
            state.empresas = payload;
        },
        upSertEmpresa: (state, {payload}) => {
            state.empresas = payload;
        },
        setEmpresaDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setEmpresaAsignar: (state, {payload}) => {
            state.combo_asignar = payload.comboAsignar;
        },
        setEmpresaAsignados: (state, {payload}) => {
            state.combo_asignados = payload.comboAsignados;
        },
        setIdEmpresa: (state, {payload}) => {
            state.IdEmpresa = payload.id;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setEmpresas1,
    upSertEmpresa,
    setEmpresaDataForm,
    setEmpresaAsignar,
    setEmpresaAsignados,
    setIdEmpresa
} = empresaSlice.actions;