import { createSlice } from '@reduxjs/toolkit';

export const cuentaContablesSlice = createSlice({
    name: 'cuentaContable',
    initialState: {
        cuentasContables: [
            // tempEvent
        ],
        getDataForm: [],
        pais_empresa: [],
        clasifica: [],
    },
    reducers: {
        setCuentaContables: (state, { payload } ) => {
            state.cuentasContables = payload.data;
        },
        setCombos: (state, { payload } ) => {
            state.pais_empresa = payload.combos.pais_empresa;
            state.clasifica = payload.combos.clasifica;
        },
        upSertCuentaContables: (state, {payload}) => {
            state.cuentasContables = payload;
        },
        setCuentaContableDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setCuentaContables,
    setCombos,
    upSertCuentaContables,
    setCuentaContableDataForm,
} = cuentaContablesSlice.actions;