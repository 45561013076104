import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useCustomerStore } from '../../hooks/Mantenimiento_Generales/useCustomerStore';
import Swal from 'sweetalert2';

export default function Standard() {
  const [formData, setFormData] = useState({
    id: 0,
    identification: '',
    name: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    contact: '',
    job: '',
    city: '',
    note: '',
    status: 1
  });

  const [errors, setErrors] = useState({ phone: '' });


  const { Upsert } = useCustomerStore();


  const location = useLocation();
  const customerData = location.state ? location.state.customerData[0] || {} : {};

  useEffect(() => {
    if (Object.keys(customerData).length > 0) {
      setFormData({
        id: customerData.id || 0,
        identification: customerData.identification || '',
        name: customerData.name || '',
        description: customerData.description || '',
        address: customerData.address || '',
        phone: customerData.phone || '',
        email: customerData.email || '',
        contact: customerData.contact || '',
        job: customerData.job || '',
        city: customerData.city || '',
        note: customerData.note || '',
        status: customerData.status || 1,
      });
    }
  }, [customerData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    Upsert({ id: formData.id, identification: formData.identification , name: formData.name, description: formData.description, address: formData.address, codepostal: formData.codePostal, phone: formData.phone, email: formData.email, contact: formData.contact, job: formData.job, city: formData.city, note: formData.note , status: formData.status });
    // Aquí puedes enviar formData a donde lo necesites
    console.log(formData);
  };



  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Mantenimiento Cientes</h4>
            </div>
          </div>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <Row className="g-4">
                <Form.Control type="hidden" name="id" value={formData.id} onChange={handleInputChange} disabled required />
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="description">Identificación</Form.Label>
                  <Form.Control type="text" placeholder="Identificacion" name="identification" value={formData.identification} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="name">Nombre Completo</Form.Label>
                  <Form.Control type="text" placeholder="Nombre" name="name" value={formData.name} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="description">Descripción</Form.Label>
                  <Form.Control type="text" placeholder="Descripcion" name="description" value={formData.description} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="phone">Teléfono</Form.Label>
                  <Form.Control type="text" placeholder="88888888" name="phone" value={formData.phone} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control type="text" placeholder="Email" name="email" value={formData.email} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="contact">Contacto</Form.Label>
                  <Form.Control type="text" placeholder="Contact" name="contact" value={formData.contact} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="job">Profesión</Form.Label>
                  <Form.Control type="text" placeholder="Profesión" name="job" value={formData.job} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="city">Ciudad</Form.Label>
                  <Form.Control type="text" placeholder="Ciudad" name="city" value={formData.city} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="address">Dirección</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Dirección" name="address" value={formData.address} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="note">Datos Extra</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Notes" name="note" value={formData.note} onChange={handleInputChange} required />
                </Form.Group>
                <Form.Group as={Col} md="3">
                  <div className="mb-4">
                    <Form.Label>Estado</Form.Label>
                    <Form.Select name="status" value={formData.status} onChange={handleInputChange} aria-label="Default select example" >
                      <option>Select</option>
                      <option value={1}>Activo</option>
                      <option value={0}>Inactivo</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Row>
              <hr />
              <div>
                <Col sm="12">
                  <Button type="submit" variant="success" className="btn-sign">Guardar</Button>
                  <Button type="submit" variant="danger" as={Link} to="/pages/customer" className="btn-sign">Cancelar</Button>
                </Col>
              </div>
            </Form>
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
