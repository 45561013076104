import { useSelector } from 'react-redux';
import services from '../../services/servicesTeso';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useMensajesStore } from '../Generales_Mensajes_Validaciones/useMensajesStore';
import { useAuthStore } from '../useAuthStore';


export const useCustomerStore = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { SuccessMensaje, SuccessDeleteMensaje, abrirCargar, abrirCargarNoTiempo, cerrarLoading } = useMensajesStore();
    const { checkAuthToken } = useAuthStore();
    let userId;
    if(!user.id){
        userId = sessionStorage.getItem('uid');
    }else {
        userId = user.id;
    }


    //Lista la información que se muestra en banco.js
    const list = async () => {
        //debugger
        checkAuthToken();
        let userId = await getUserId();
        const token = localStorage.getItem('token');
        if(token != null) {
            checkAuthToken();
            try {
                //debugger
                //abrirCargar();
                const headerAxios = {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
                //console.log("url:" + services.API.Customer.List);
                const { data } = await axios.post(services.API.Customer.List, 
                 { userId }, {headers: headerAxios});            
                if (data.respuesta === "success") {
                    for (var i = 0; i < data.Data.length; i++) {
                        if (data.Data[i].status === 1) {
                            data.Data[i].status = "Activo";
                        } if (data.Data[i].status == 0) {
                            data.Data[i].status = "Inactivo";
                        }
                    }
                    return data.Data;
                    //dispatch(setBancos({ bancos: data.data }));
                    //cerrarCargar();
                } else {
                    if (data.respuesta_tipo === "warning") {
                        debugger
                        NoPermisoMensaje();
                    }
                    if (data === "") {
                        debugger
                        NoPermisoMensaje();
                    }
                }
            } catch (error) {
                debugger
                ErrorMensaje();
                console.log("error:" + error);
                throw error; // Puedes propagar el error para manejarlo en el componente
            }
        }
       
    }

    const getUserId = async () => {
        return new Promise((resolve, reject) => {
            try {
                let userId;
                if (!user.id) {
                    userId = sessionStorage.getItem('uid');
                } else {
                    userId = user.id;
                }
                resolve(userId);
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleErrorResponse = (data) => {
        if (data.respuesta_tipo === "warning") {
            NoPermisoMensaje();
        } else if (!data) {
            NoPermisoMensaje();
        }
    };

     //Ingresa Data de manera masiva 
     const UploadDataMasive = async ( jsonData ) => {
        debugger
        abrirCargarNoTiempo();
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            let data = [];
            jsonData = JSON.stringify(jsonData);
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'maxContentLength': Infinity, // Asegúrate de no limitar el tamaño del contenido
                'maxBodyLength': Infinity    // Asegúrate de no limitar el tamaño del cuerpo
                        }
            
                  data  = await axios.post(services.API.Customer.upload, { userId, jsonData }, { headers: headerAxios });
            
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                cerrarLoading();
                console.log("data: " + data);
                
                SuccessMensaje();
                //dispatch(upSertBanco({ bancos: data.data }));
                navigate('/pages/customer');
            }
            if (data.status === 400) {
                cerrarLoading();
                Swal.fire(
                    'Error',
                    data.message,
                )
            }
        } catch (error) {
            console.log("error:" + error);
            Swal.fire(
                'Error.',
                error.response.data.message,
                'error'
            )
        }
    }

    //Actualiza e inserta bancos. 
    const Upsert = async ({ id, identification, name, description, address, code_postal, phone, email, contact, job, city, note, status }) => {
        // debugger

        const token = localStorage.getItem('token');
        if (status == "1" || status == true) {
            status = true;
        } else {
            status = false;
        }
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                        }
            if(id == undefined || id == 0){
                  data  = await axios.post(services.API.Customer.Create, { userId, identification, name, description, address, code_postal, phone, email, contact, job, city, note, status }, { headers: headerAxios });
            } else {
                  data  = await axios.post(services.API.Customer.Update, { id, identification, name, description, address, code_postal, phone, email, contact, job, city, note }, { headers: headerAxios });
            }
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                console.log("data: " + data);
                SuccessMensaje();
                //dispatch(upSertBanco({ bancos: data.data }));
                navigate('/pages/customer');
            }
            if (data.data.respuesta === "warning") {
                Swal.fire(
                    'Data duplicated',
                    'Input another data.',
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    //Actualiza e inserta bancos. 
    const UpsertPayment = async ({ paymentId, paymentDate, amount, paymentMethod, statusPayment, nextPaymentDate, notify, notes }) => {
        debugger

        const token = localStorage.getItem('token');
        
        checkAuthToken();
        try {
            let data = [];
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                    }
                    
                  amount = parseFloat(amount);
                  data  = await axios.post(services.API.Payment.UpdateDataPayment, { paymentId, paymentDate, amount, paymentMethod, statusPayment, nextPaymentDate, notify, notes }, { headers: headerAxios });
            
            //const { data } = await axios.post(services.API.Banco.ApiTesoreria_Mant_Banco_Actualizar, { userId, id, name, description, status }, { headers: headerAxios });
            if (data.data.respuesta === "success") {
                console.log("data: " + data);
                SuccessMensaje();
                //dispatch(upSertBanco({ bancos: data.data }));
                navigate('/pages/customerPayment');
            }
            if (data.data.respuesta === "warning") {
                Swal.fire(
                    'Error',
                    'Contact Admin.',
                )
            }
        } catch (error) {
            console.log("error:" + error);
            ErrorMensaje();
        }
    }

    //Get Data to update.
    const getDataUpdate = async (id) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Customer.GetData, { id }, { headers: headerAxios });
            if (data.respuesta === "success") {
                //history.pushState('')
                //history.push(`/pages/customerEdit/${id}`, { customerData });
                //navigate('/pages/bancoEditar');
                return data.Object
                // navigate('/pages/bancoEditar');
                // dispatch(setBancoDataForm({ getData: data.data.banco }));
                // dispatch(setPaises({ paises: data.data.paises }));

            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

    //Get Data to update.
    const getDataPaymentUpdate = async (id) => {
        //debugger
        const token = localStorage.getItem('token');
        checkAuthToken();
        try {
            const headerAxios = {
                'content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            abrirCargar();
            const { data } = await axios.post(services.API.Payment.GetDataPayment, { id }, { headers: headerAxios });
            if (data.respuesta === "success") {
                //history.pushState('')
                //history.push(`/pages/customerEdit/${id}`, { customerData });
                //navigate('/pages/bancoEditar');
                return data.Object
                // navigate('/pages/bancoEditar');
                // dispatch(setBancoDataForm({ getData: data.data.banco }));
                // dispatch(setPaises({ paises: data.data.paises }));

            }
        } catch (error) {
            console.log("error:" + error);
        }
    }

    //Elimina 
    const Delete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás deshacer esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Aquí se ejecuta la acción si el usuario confirma
                const token = localStorage.getItem('token');
                checkAuthToken();
                try {
                    const headerAxios = {
                        'content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                    const { data } = await axios.post(services.API.Customer.Delete, { id }, { headers: headerAxios });
                    if (data.respuesta === "success") {
                        console.log("data: " + data);
                        SuccessDeleteMensaje();
                    }
                    if (data.respuesta_tipo === "warning") {
                        Swal.fire(
                            'Problema con el Banco',
                            data.respuesta_detalle,
                        )
                    }
                } catch (error) {
                    console.log("error:" + error);
                    ErrorMensaje();
                }
            }
        });
    }
    
    // const Delete = async (id) => {
    //     //debugger
    //     const token = localStorage.getItem('token');
    //     checkAuthToken();
    //     try {
    //         const headerAxios = {
    //             'content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         }
    //         const { data } = await axios.post(services.API.Customer.Delete, { id }, { headers: headerAxios });
    //         if (data.respuesta === "success") {
    //             console.log("data: " + data);
    //             SuccessDeleteMensaje();
    //         }
    //         if (data.respuesta_tipo === "warning") {
    //             Swal.fire(
    //                 'Problema con el Banco',
    //                 data.respuesta_detalle,
    //             )
    //         }
    //     } catch (error) {
    //         console.log("error:" + error);
    //         ErrorMensaje();
    //     }
    // }


    const NoPermisoMensaje = async () => {
        Swal.fire(
            'No posee permisos para realizar esta acción.',
            'Contacte un administrador.',
            'question'
        )
    }

    const ErrorMensaje = async () => {
        Swal.fire(
            'Ha ocurrido un error.',
            'Contacte un administrador.',
            'error'
        )
    }

    return {
        //* Métodos
        list,
        Upsert,
        UploadDataMasive,
        UpsertPayment,
        getDataUpdate,
        getDataPaymentUpdate,
        Delete,
    }

}