import { createSlice } from '@reduxjs/toolkit';

export const pagosServiciosSlice = createSlice({


    name: 'pagoServicios',
    initialState: {
        pagosServicios: [
            // tempEvent
        ],
        getDataForm: [],
        empresas: [],
        convenios: [],
        llaves: [],
        centros: [],
        datosLlave: [],
        recurrencias: [],
        indiceEmpresa: [],
        indiceConv: [],
        centros: [],
        cuentas: [],
        idLlaveSelec: [],
        idLlaveValorSelec: [],
        boton: {},
        pse_listacentros: [],

    },
    reducers: {
        upSertPagosServicios: (state, { payload }) => {
            state.pagosServicios = payload;
        },
        setPagosServicios: (state, { payload }) => {
            state.pagosServicios = payload;
        },
        setPagosEmpresas: (state, { payload }) => {
            state.empresas = payload;
        },
        setPagosConvenios: (state, { payload }) => {
            state.convenios = payload;
        },
        setPagosLlaves: (state, { payload }) => {
            state.llaves = payload;
        },
        setPagosServiciosDataForm: (state, { payload }) => {
            state.getDataForm = payload.getData.data.pagos_servicios;
        },
        setPagosServiciosDataForm2: (state, { payload }) => {
            state.getDataForm = payload.getData;
        },
        setDataEmpresasSelects: (state, { payload }) => {
            state.empresas = payload.selects.empresas;
        },
        setDataConveniosSelects: (state, { payload }) => {
            state.convenios = payload.selects.convenios;
        },
        setDataLlavesSelects: (state, { payload }) => {
            state.llaves = payload.selects.llaves;
        },
        setVerificarLlave: (state, { payload }) => {
            state.datosLlave = payload;
        },
        setCentrosSelects: (state, { payload }) => {
            state.centros = payload.selects.centros;
        },
        setCuentasSelects: (state, { payload }) => {
            state.cuentas = payload.selects.cuentas;
        },
        setRecurrenciasSelects: (state, { payload }) => {
            state.recurrencias = payload.selects.recurrencias;
        },
        setDataLlave: (state, { payload }) => {
            state.idLlaveSelec = payload.llave;
        },
        setDataLlaveValor: (state, { payload }) => {
            state.idLlaveValorSelec = payload.llave;
        },
        setPseLlaveValor: (state, { payload }) => {
            state.pseLlaveValor = payload;
        },
        setLlavesCombo: (state, { payload }) => {
            state.llavesCombo = payload;
        },
        setBoton: (state, { payload }) => {
            state.boton = payload;
        },
        setListaCentros: (state, { payload }) => {
            state.pse_listacentros = payload.getData.data.pse_listacentros;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setPagosServicios,
    upSertPagosServicios,
    setPagosServiciosDataForm,
    setPagosServiciosDataForm2,
    setDataPagosServiciosSelects,
    setDataEmpresasSelects,
    setDataConveniosSelects,
    setDataIndiceEmpresa,
    setDataLlavesSelects,
    setDataIndiceConv,
    setRecurrenciasSelects,
    setVerificarLlave,
    setCentrosSelects,
    setCuentasSelects,
    setDataLlave,
    setPseLlaveValor,
    setLlavesCombo,
    setBoton,
    setListaCentros,    
} = pagosServiciosSlice.actions;