import { createSlice } from '@reduxjs/toolkit';

export const monedaSlice = createSlice({
    name: 'moneda',
    initialState: {
        monedas: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setMonedas: (state, { payload } ) => {
            state.monedas = payload;
        },
        upSertMoneda: (state, {payload}) => {
            state.monedas = payload.respuesta.monedas;
        },
        setMonedaDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setMonedas,
    upSertMoneda,
    setMonedaDataForm
} = monedaSlice.actions;