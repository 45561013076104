import { createSlice } from '@reduxjs/toolkit';

export const MetPagoXOperativaSlice = createSlice({
    name: 'metPagoXOperativa',
    initialState: {
        metPagoXOperativas: [
            // tempEvent
        ],
        getDataForm: [],
        mpa_lista: [],
        pae_lista: []
    },
    reducers: {
        setMetPagoXOperativas: (state, { payload } ) => {
            state.metPagoXOperativas = payload;
        },
        upSertMetPagoXOperativa: (state, {payload}) => {
            state.metPagoXOperativas = payload;
        },
        setMetPagoXOperativasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataMetPagoXOperativaSelects: (state, {payload}) => {
            state.mpa_lista = payload.selects.mpa_lista;
            state.pae_lista = payload.selects.pae_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setMetPagoXOperativas,
    upSertMetPagoXOperativa,
    setMetPagoXOperativasDataForm,
    setDataMetPagoXOperativaSelects
} = MetPagoXOperativaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 