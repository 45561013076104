import { createSlice } from '@reduxjs/toolkit';

export const transaccionSlice = createSlice({
    name: 'transaccion',
    initialState: {
        transacciones: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setTransacciones: (state, { payload } ) => {
            state.transacciones = payload;
        },
        upSertTransaccion: (state, {payload}) => {
            state.transacciones = payload.respuesta.transacciones;
        },
        setTransaccionDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setTransacciones,
    upSertTransaccion,
    setTransaccionDataForm
} = transaccionSlice.actions;