import { createSlice } from '@reduxjs/toolkit';

export const solicitudSlice = createSlice({
    name: 'solicitud',
    initialState: {
        solicitudes: [
            // tempEvent
        ],
        getDataForm: [],
        estados: []
    },
    reducers: {
        setSolicitudes: (state, { payload } ) => {
            state.solicitudes = payload;
        },
        upSertSolicitudes: (state, {payload}) => {
            state.personasConCuentas = payload;
        },
        setSolicitudesDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
         setDataSolicitudesSelects: (state, {payload}) => {
            state.estados = payload.selects.estados;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
     setSolicitudes,
     upSertSolicitudes,
     setSolicitudesDataForm,
     setDataSolicitudesSelects,
} = solicitudSlice.actions;