import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/scss/panels/_paginaCentral.scss";

export default function Main() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  useEffect(() => {
    offsets.includes(pathname) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");
    bc.remove("sidebar-show");
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <Sidebar />
      <div className="paginaCentral main main-app p-3 p-lg-4">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </React.Fragment>
  );
}
