import { createSlice } from '@reduxjs/toolkit';

export const usuariosXCuentaSlice = createSlice({
    name: 'usuariosCuentas',
    initialState: {
        usuariosXCuenta: [
            // tempEvent
        ],
        getDataForm: [],
        corrientes: [],
        cuentasIban: [],
        usuarios: [],      

    },
    reducers: {
        setUsuariosXCuenta: (state, { payload } ) => {
            state.usuariosXCuenta = payload;
        },
        setUsuarioslist: (state, { payload }) => {
            state.usuarios = payload;
        },
        setCorrientes: (state, { payload }) => {
            state.corrientes = payload;
        },
        setCuentasIban: (state, { payload }) => {
            state.cuentasIban = payload;
        },
        upSertUsuariosXCuenta: (state, { payload }) => {
            state.usuariosXCuenta = payload;
        },
        setUsuariosCuentasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setUsuariosXCuenta,
    setUsuarioslist,
    setCorrientes,
    setCuentasIban,
    upSertUsuariosXCuenta,
    setUsuariosCuentasDataForm

} = usuariosXCuentaSlice.actions;