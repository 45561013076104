import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import { Grid, _ } from "gridjs-react";
import { h } from "gridjs";
import { Card, Button, Container } from "react-bootstrap";
import { useInventoryStore } from '../../hooks/Inventory/useInventoryStore';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx'; // Importar toda la biblioteca como un objeto XLSX
import jsPDF from 'jspdf'; // Importar jspdf
import 'jspdf-autotable'; // Importar jspdf-autotable
import Swal from 'sweetalert2';


export default function Standard() {
  const [Data, setData] = useState([]); // Estado local para almacenar los datos de la lista
  const { list, Delete, getDataUpdate } = useInventoryStore();
  const navigate = useNavigate();

  useEffect(() => {
      getList();
  }, []);

  const getList = async () => {
    try {
      const data = await list();
      setData(data);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const handleDelete = async (id) => {
    try {
      
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to perform this action?',
        text: 'This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        cancelButtonText: 'No, cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {
          
          await Delete(id);
          getList();

          Swal.fire({
            icon: 'success',
            title: 'Data deleted',
            text: 'The data has been deleted successfully'
          });
        }
      });


      // await Delete(id);
      // getList();
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const handleGetData = async (id) => {
    try {
      //debugger
      const inventoryData = await getDataUpdate(id);
      navigate(`/pages/inventoryEdit/`, { state: { inventoryData } });
    } catch (error) {
      console.log("error:" + error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(Data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "InventoryData.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF(); // Crear un nuevo documento PDF
    doc.autoTable({ // Utilizar autotable para crear una tabla en el PDF
      head: [['Name', 'Description', 'Status']], // Encabezados de las columnas
      body: Data.map(({ name, description, status }) => [name, description, status]) // Datos de las filas
    });
    doc.save('ProductData.pdf'); // Guardar el documento PDF
  };

  return (
    <React.Fragment>
      <Container fluid="md" className="mb-5">
        <Header />
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Inventario</h4>
            </div>
            <div>
              <Button variant="dark" onClick={exportToExcel} className="ml-2">Exportar Excel</Button>
              <Button variant="dark" onClick={exportToPDF} className="ml-2">Exportar PDF</Button>
            </div>
            <div>
              <Button variant="success" as={Link} to="/pages/inventoryEdit">Guardar</Button>
            </div>
          </div>
          <Card.Body>
            <Grid
              data={Data}
              columns={[
                { name: 'id', hidden: true },
                { id: 'transaction_date', name: 'Fecha' },
                { id: 'invoice', name: '# Factura' },
                { id: 'comments', name: 'Comentarios' },
                { id: 'supplier_name', name: 'Proveedor' },
                { id: 'warehouse_name', name: 'Almacen' },
                { id: 'status', name: 'Estado' },
                {
                  name: 'Editar',
                  formatter: (cell, row) => {
                    return h('Button', {
                      className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-warning bg-blue-600',
                      onClick: () => handleGetData(`${row.cells[0].data}`)
                    }, 'Editar');
                  }
                },
                {
                  name: 'Eliminar',
                  formatter: (cell, row) => {
                    return h('Button', {
                      className: 'py-2 mb-4 px-4 border rounded-md text-white btn btn-danger',
                      onClick: () => handleDelete(`${row.cells[0].data}`)
                    }, 'Eliminar');
                  }
                },
              ]}
              search={true}
              pagination={{
                enabled: true,
                limit: 5
              }}
              sort={true}
              resizable={true}
              language={{
                search: {
                  placeholder: 'Write to search...',
                },
                sort: {
                  sortAsc: 'Ascending column order.',
                  sortDesc: 'Descending column order.',
                },
                pagination: {
                  previous: 'Previous',
                  next: 'Next',
                  navigate: (page, pages) => `Page ${page} of ${pages}`,
                  page: (page) => `Page ${page}`,
                  showing: 'Showing of',
                  of: 'of',
                  to: 'to',
                  results: 'records',
                },
                loading: 'Loading...',
                noRecordsFound: 'No matches found.',
                error: 'An error occurred while obtaining the data.',
              }}
              className={{
                table: 'table table-bordered mb-0'
              }}
            />
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
