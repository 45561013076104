import { createSlice } from '@reduxjs/toolkit';

export const recurrenciaPagoSlice = createSlice({
    name: 'recurrenciaPago',
    initialState: {
        recurrenciaPagos: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setRecurrenciaPagos: (state, { payload } ) => {
            state.recurrenciaPagos = payload;
        },
        upSertRecurrenciaPago: (state, {payload}) => {
            state.recurrenciaPagos = payload;
        },
        setRecurrenciaPagosDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setRecurrenciaPagos,
    upSertRecurrenciaPago,
    setRecurrenciaPagosDataForm
} = recurrenciaPagoSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 