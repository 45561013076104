import axios from 'axios';
//import { getEnvVariables } from '../helpers';

//const { VITE_API_URL } = getEnvVariables()
const { VITE_API_URL } = 'localhost:4000';

const payControllerApi = axios.create({
    baseURL: VITE_API_URL
});

// Todo: configurar interceptores
payControllerApi.interceptors.request.use( config => {

    config.headers = {
        ...config.headers,
        'x-token': localStorage.getItem('token')
    }

    return config;
})


export default payControllerApi;



