import { createSlice } from '@reduxjs/toolkit';

export const saldosBancariosSlice = createSlice({
    name: 'saldoBancario',
    initialState: {
        saldosBancarios: [],
        ListaBancosSaldos: [],
        combo_pae: [],
        combo_ban: [],
        combo_iban: [],
        combo_cuenta: [],
        combo_pai: [],
        combo_mon: [],
        fechaIni: [],
        fechaFin: []
    },
    reducers: {
        setSaldosBancarios: (state, { payload } ) => {
            state.saldosBancarios = payload;
        },
        setListaMovimientosReportes: (state, { payload } ) => {
            state.ListaBancosSaldos = payload.saldosBancarios;
        },
        setCombosFiltros: (state, { payload } ) => {
            state.combo_pae = payload.combos.combo_pae;
            state.combo_ban = payload.combos.combo_ban;
            state.combo_iban = payload.combos.combo_iban;
            state.combo_cuenta = payload.combos.combo_cuenta;           
            state.combo_pai = payload.combos.combo_pai;
            state.combo_mon = payload.combos.combo_mon;
            state.ecb_fecha_fin = payload.combos.ecb_fecha_fin;
            state.ecb_fecha_ini = payload.combos.ecb_fecha_ini;            
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setSaldosBancarios,
    setListaMovimientosReportes,
    setCombosFiltros,
} = saldosBancariosSlice.actions;