import { createSlice } from '@reduxjs/toolkit';

export const MonedaXOperativaSlice = createSlice({
    name: 'monedaXOperativa',
    initialState: {
        monedaXOperativas: [
            // tempEvent
        ],
        getDataForm: [],
        mon_lista: [],
        pae_lista: []
    },
    reducers: {
        setMonedaXOperativas: (state, { payload } ) => {
            state.monedaXOperativas = payload;
        },
        upSertMonedaXOperativa: (state, {payload}) => {
            state.monedaXOperativas = payload;
        },
        setMonedaXOperativasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataMonedaXOperativaSelects: (state, {payload}) => {
            state.mon_lista = payload.selects.mon_lista;
            state.pae_lista = payload.selects.pae_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setMonedaXOperativas,
    upSertMonedaXOperativa,
    setMonedaXOperativasDataForm,
    setDataMonedaXOperativaSelects
} = MonedaXOperativaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 