import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import { Card, Button, Container, Form, Row, Col } from "react-bootstrap";
import { useCustomerStore } from '../../hooks/Mantenimiento_Generales/useCustomerStore';
import * as XLSX from 'xlsx'; // Importar toda la biblioteca como un objeto XLSX
import 'jspdf-autotable'; // Importar jspdf-autotable
import Estructura from '../../styles/assets/img/EstructuraExcel.png';
import Swal from 'sweetalert2';


export default function Standard() {
  const [file, setFile] = useState(null); // Estado para almacenar el archivo cargado
  const { UploadDataMasive } = useCustomerStore();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleUpload = async () => {
    if (!file) return;
    //debugger
  
    const requiredColumns = [
      "identification",
      "name",
      "description",
      "address",
      "code_postal",
      "phone",
      "email",
      "contact",
      "job",
      "city",
      "note"
    ];
  
    const isValidInput = (value) => {
      // Permitir caracteres alfanuméricos, espacios, @, puntos, +, -, y paréntesis
      const regex = /^[a-zA-Z0-9\s@.+()-]+$/;
      return regex.test(value);
    };
  
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target?.result;
      if (!data) return;
  
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(sheet);
  
      // Filtrar filas que tienen al menos una columna obligatoria con datos
      jsonData = jsonData.filter(row => {
        return requiredColumns.some(column => row[column] && row[column].toString().trim() !== "");
      });
  
      // Validar la estructura del archivo
      if (jsonData.length === 0) {
        Swal.fire(
          'Error!',
          'El archivo no contiene datos válidos.',
          'error'
        );
        return;
      }
  
      const firstRow = jsonData[0];
      const columns = Object.keys(firstRow);
  
      const missingColumns = requiredColumns.filter(col => !columns.includes(col));
  
      if (missingColumns.length > 0) {
        Swal.fire(
          'Error!',
          `El archivo está incompleto. Faltan las siguientes columnas: ${missingColumns.join(", ")}`,
          'error'
        );
        return;
      }

       // Validar que ninguna columna esté vacía y proteger contra inyecciones
    const hasInvalidData = jsonData.some(row => {
      return requiredColumns.some(column => {
        const value = row[column];
        if (!value || value.toString().trim() === "" || !isValidInput(value)) {
          console.error(`Columna con error: ${column}, Valor problemático: "${value}"`);
          return true;
        }
        return false;
      });
    });

    if (hasInvalidData) {
      Swal.fire(
        'Error!',
        'El archivo contiene campos vacíos o caracteres no permitidos en algunas filas. Todos los campos son obligatorios y deben contener caracteres válidos.',
        'error'
      );
      return;
    }
  
      // // Validar que ninguna columna esté vacía y proteger contra inyecciones
      // const hasInvalidData = jsonData.some(row => {
      //   return requiredColumns.some(column => {
      //     const value = row[column];
      //     console.log(`Validando columna: ${column}, valor: "${value}"`);
      //     return !value || value.toString().trim() === "" || !isValidInput(value);
      //   });
      // });
  
      // if (hasInvalidData) {
      //   Swal.fire(
      //     'Error!',
      //     'El archivo contiene campos vacíos o caracteres no permitidos en algunas filas. Todos los campos son obligatorios y deben contener caracteres válidos.',
      //     'error'
      //   );
      //   return;
      // }
  
      // Validar el número de registros
      if (jsonData.length > 1000) {
        Swal.fire(
          'Warning.',
          'El archivo contiene más de 1000 registros. El límite es de 1000 registros.',
          'question'
        );
        return;
      }
  
      try {
        await UploadDataMasive(jsonData);
      } catch (error) {
        Swal.fire(
          'Error!',
          `Ocurrió un error durante la carga de datos: ${error.message}`,
          'error'
        );
        console.error("error:", error);
      }
    };
  
    reader.readAsBinaryString(file);
  };

  return (
    <React.Fragment>
      <Container fluid="md" className="mb-5">
        <Header />
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Mantenimiento Clientes Masivos</h4>
            </div>
          </div>
          <Card.Body>
          <Form>
            <Row className="g-4">
              <Form.Group as={Col} md="12">
                <h2>Instrucciones para Cargar Datos de Clientes desde Excel</h2>
                <Form.Label htmlFor="name">
                <p>Esta funcionalidad permite leer los datos de clientes desde un documento Excel en formato <code>.xlsx</code> y realizar una inserción masiva de los datos.</p>

                <h4>Notas Importantes:</h4>
                <ul>
                    <li><strong>Límite:</strong> El límite establecido por carga es de 1000 registros.</li>
                    <li><strong>No Modificar Columnas:</strong> Es fundamental que no se realicen modificaciones en la estructura del archivo Excel adjunto. Alterar su formato puede comprometer la funcionalidad del proceso de carga masiva.</li>
                    <li><strong>Completa Todos los Campos:</strong> Asegúrate de que todos los campos del Excel estén completos. No debe haber datos vacíos.</li>
                    <li><strong>Formato de Números de Teléfono:</strong> Para el correcto envío de notificaciones, ingresa los números de teléfono en el formato de código de país. Por ejemplo: <code>+50661372755</code>. Los números no deben incluir espacios.</li>
                    <li><strong>Tiempo de Procesamiento:</strong> La operación puede demorar unos minutos. Por favor, no realices ninguna acción mientras esperas a que se complete.</li>
                </ul>
                <br />
                <p>
                  Se adjunta el excel como machote y una imagen de ejemplo de la estructura:
                  <a href="/machoteClientes.xlsx" download>
                    <Button variant="primary" style={{ marginLeft: '10px' }}>Descargar Machote</Button>
                  </a>
                </p>
                <img 
                  src={Estructura} 
                  alt="Ejemplo de estructura de Excel" 
                  style={{ width: '100%', maxWidth: '100%', height: 'auto' }} 
                />
               </Form.Label>
              </Form.Group>
            </Row>
            <hr />
            <div>
              <Col sm="12">
              <div>
              <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
              <Button variant="primary" onClick={handleUpload} className="ml-2">Upload Excel</Button>
            </div>              
              </Col>
            </div>
          </Form>
        </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
