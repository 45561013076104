import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        usuarios: [
            // tempEvent
        ],
        tipo_usuarios: [],
        roles: [],
        redireccion: [],
        getDataForm: [],
    },
    reducers: {
        setUsers: (state, { payload } ) => {
            state.usuarios = payload;
        },
        setDataUpsert: (state, { payload, roles  } ) => {
            state.tipo_usuarios = payload.tipo_usuarios.tipo_usuarios;
            state.roles = payload.tipo_usuarios.roles;
        },
        upSertUsert: (state, {payload}) => {
            state.usuarios = payload.respuesta.usuarios;
            state.redireccion = payload.respuesta.respuesta_tipo;
        },
        setUserDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setUsers,
    setDataUpsert,
    upSertUsert,
    setUserDataForm
} = userSlice.actions;