import { createSlice } from '@reduxjs/toolkit';

export const personasConCuentaSlice = createSlice({
    name: 'personasConCuenta',
    initialState: {
        personasConCuentas: [
            // tempEvent
        ],
        getDataForm: [],
        tid_lista: [],
        sex_lista: [],
        telefonos: [],
        supervisores: [],
        cuentas_bancarias: [],        
        mon_lista: [],
        clp_lista: [],
        ban_lista: [],

    },
    reducers: {
        setPersonasConCuentas: (state, { payload } ) => {
            state.personasConCuentas = payload;
        },
        setPersonasConTelefonos: (state, { payload } ) => {
            state.telefonos = payload;
        },
        setPersonasCuentas: (state, { payload } ) => {
            state.cuentas_bancarias = payload;
        },
        upSertPersonasConCuenta: (state, {payload}) => {
            state.personasConCuentas = payload;
        },
        upSertPersonasConTelefonos: (state, {payload}) => {
            state.telefonos = payload;
        },
        upSertPersonasCuentas: (state, {payload}) => {
            state.cuentas_bancarias = payload;
        },
        setPersonasConCuentasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataPersonasSelects: (state, {payload}) => {
            state.tid_lista = payload.selects.tid_lista;
            state.sex_lista = payload.selects.sex_lista;
            state.telefonos = payload.selects.telefonos;
            state.supervisores = payload.selects.supervisores;
            state.cuentas_bancarias = payload.selects.cuentas_bancarias;
            state.mon_lista = payload.selects.mon_lista;            
            state.clp_lista = payload.selects.clp_lista;
            state.ban_lista = payload.selects.ban_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setPersonasConCuentas,
    upSertPersonasConCuenta,
    setPersonasConCuentasDataForm,
    setDataPersonasSelects,
    setPersonasConTelefonos,
    upSertPersonasConTelefonos,
    upSertPersonasCuentas,
    setPersonasCuentas,            
} = personasConCuentaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 