import Swal from 'sweetalert2';

export const useMensajesStore = () => {
    //Mensajes Swal
      const SuccessMensaje = async() => {   
          Swal.fire(
        'Success!',
        '¡Agregado con éxito!',
        'success'
      )
    }

    const SuccessMensajeSend = async() => {   
      Swal.fire(
    'Success!',
    '¡Éxito!',
    'success'
  )
}

    const SuccessDeleteMensaje = async() => {   
      Swal.fire(
    'Success!',
    '¡Eliminado con éxito!',
    'success'
  )
}

    const abrirCargar = async() => {   
        Swal.fire({
            title: 'Cargando!',
            html: 'Un momento.',
            timer: 1500,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading() 
            },
          })
    }

    const abrirCargarNoTiempo = async() => {   
      Swal.fire({
          title: 'Cargando!',
          html: 'Un momento.',
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading() 
          },
        })
  }

      const cerrarLoading = async () => {
        Swal.close();
    }


    const NoPermisoMensaje = async() => {   
        Swal.fire(
            'No posee permisos para realizar esta acción.',
            'Contacte un administrador.',
            'question'
          )
    }

    const NoCamposVaciosCuentaBancaria = async() => {   
      Swal.fire(
          'Aviso!.',
          'Por favor seleccionar Banco y Moneda.',
          'question'
        )
  }

  const NoCamposVaciosPrograReporte = async() => {   
    Swal.fire(
        'Aviso!.',
        'Por favor seleccionar Reporte y Recurrencia.',
        'question'
      )
}

    const ErrorMensaje = async() => {   
        Swal.fire(
            'Ha ocurrido un error.',
            'Contacte un administrador.',
            'error'
          )
    }

    return {
        //* Métodos
        SuccessMensaje,
        SuccessMensajeSend,
        SuccessDeleteMensaje,
        abrirCargar,
        abrirCargarNoTiempo,
        cerrarLoading,
        NoPermisoMensaje,
        ErrorMensaje,
        NoCamposVaciosCuentaBancaria,
        NoCamposVaciosPrograReporte
    }

}