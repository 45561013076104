import { createSlice } from '@reduxjs/toolkit';

export const rolSlice = createSlice({
    name: 'rol',
    initialState: {
        roles: [],
        sistemas: [],
        getDataForm: [],
    },
    reducers: {
        setRoles: (state, { payload } ) => {
            state.roles = payload;
        },
        setDataSelectRoles: (state, { payload  } ) => {
            state.sistemas = payload.sistemas.sistemas;
        },
        upSertRol: (state, {payload}) => {
            state.roles = payload.respuesta.roles;
        },
        setRolDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setRoles,
    setDataSelectRoles,
    upSertRol,
    setRolDataForm
} = rolSlice.actions;