import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom"; // Asegúrate de importar NavLink aquí
import { useSelector, useDispatch } from 'react-redux';
import "../styles/cssMenu.css"; 
import "react-perfect-scrollbar/dist/css/styles.css";
import { maintenance, transactions, info, uiElementsMenu, pagesMenu } from "../sharedComponents/data/Menu";
import logo from '../styles/assets/img/LogoNav.png';


export default function Sidebar() {
    const [isMobile, setIsMobile] = useState(false);
    const { showMenu } = useSelector(state => state.auth); // Aquí puedes acceder al estado global
    const dispatch = useDispatch();
    //dispatch( setRoles({ roles: data.roles }) );


    useEffect(() => {
        const detectMobileDevice = () => {
            const isMobile = window.innerWidth <= 768;
            setIsMobile(isMobile);
        };

        detectMobileDevice(); // Verifica si es móvil al montar
        window.addEventListener("resize", detectMobileDevice);

        return () => {
            window.removeEventListener("resize", detectMobileDevice); // Limpia el listener al desmontar
        };
    }, []);

    const toggleSidebar = (e) => {
        console.log('showMenu: ' + showMenu + ' ' + 'isMobile: ' + isMobile);
        e.preventDefault();
        let isOffset = document.body.classList.contains("sidebar-offset");
        if (isOffset) {
            document.body.classList.toggle("sidebar-show");
        } else {
            if (window.matchMedia("(max-width: 991px)").matches) {
                document.body.classList.toggle("sidebar-show");
            } else {
                document.body.classList.toggle("sidebar-hide");
            }
        }
    };

//     //     toggleMenu = () => {
// //         //debugger
// //         this.setState({ showMenu: !this.state.showMenu });
// //     }

    return (
        <div className={`sidebar ${showMenu && isMobile ? "show-menu" : ""}`}>
            <div className="sidebar-header">
                     <Link to="/pages/home" className="sidebar-logo"> <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto', alignItems: 'center' }} /> </Link>  
                </div>
            <div className="sidebar-body" style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}>
                <SidebarMenu />
            </div>
            {isMobile && (
                <button className="hamburger-menu" onClick={toggleSidebar}>
                    <i className="ri-menu-line"></i>
                </button>
            )}
        </div>
    );
}

function SidebarMenu() {
    const populateMenu = (m) => {
        return (
            <ul className="nav nav-sidebar">
                {m.map((m, key) => (
                    <li key={key} className="nav-item">
                        {m.submenu ? (
                            <>
                                <div onClick={(e) => toggleSubMenu(e)} className="nav-link has-sub">
                                    <i className={m.icon}></i> <span>{m.label}</span>
                                </div>
                                <nav className="nav nav-sub">
                                    {m.submenu.map((sm, key) => (
                                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                                    ))}
                                </nav>
                            </>
                        ) : (
                            <NavLink to={m.link} className="nav-link">
                                <i className={m.icon}></i> <span>{m.label}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const toggleSubMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');
    };

    return (
        <React.Fragment>
            <div className="nav-group show">
                <div className="nav-label">Transacciones</div>
                {populateMenu(transactions)}
            </div>
            <div className="nav-group show">
                <div className="nav-label">Mantenimientos</div>
                {populateMenu(maintenance)}
            </div>
            <div className="nav-group show">
                <div className="nav-label">Información</div>
                {populateMenu(info)}
            </div>
            {/* <div className="nav-group show">
                <div className="nav-label">Page Munu</div>
                {populateMenu(pagesMenu)}
            </div>
            <div className="nav-group show">
                <div className="nav-label">UI Elements</div> 
                {populateMenu(uiElementsMenu)}
            </div>  */}
        </React.Fragment>
    );
}



// import React, { Component } from "react";
// import { Link, NavLink } from "react-router-dom";
// import "../styles/cssMenu.css"; // Asegúrate de que la ruta sea correcta aquí
// import "react-perfect-scrollbar/dist/css/styles.css";
// import {
//     maintenance,
//     transactions,
//     uiElementsMenu,
//     pagesMenu,
//     invoice
// } from "../sharedComponents/data/Menu";
// import logo from '../styles/assets/img/LogoNav.png';
// import { useDispatch, useSelector } from 'react-redux';


// export default class Sidebar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             showMenu: false, // Estado para mostrar/ocultar el menú en dispositivos móviles
//             isMobile: false // Estado para detectar si es un dispositivo móvil
//         };
//     }
//     //const { showMenuGlobal } = useSelector(state => state.auth);


//     componentDidMount() {
//         // Detectar si es un dispositivo móvil al montar el componente
//         this.detectMobileDevice();
//         // Escuchar cambios en el tamaño de la ventana para ajustar el estado
//         window.addEventListener("resize", this.detectMobileDevice);
//     }

//     componentWillUnmount() {
//         // Limpiar el listener del evento de resize al desmontar el componente
//         window.removeEventListener("resize", this.detectMobileDevice);
//     }

//     detectMobileDevice = () => {
//         //debugger
//         const isMobile = window.innerWidth <= 768; // Definir el límite para dispositivos móviles
//         if (isMobile !== this.state.isMobile) {
//             this.setState({ isMobile });
//         }
//     }

//     toggleMenu = () => {
//         //debugger
//         this.setState({ showMenu: !this.state.showMenu });
//     }

//     toggleSidebar = (e) => {
//         debugger
//         e.preventDefault();
//         let isOffset = document.body.classList.contains("sidebar-offset");
//         if (isOffset) {
//           document.body.classList.toggle("sidebar-show");
//         } else {
//           if (window.matchMedia("(max-width: 991px)").matches) {
//             document.body.classList.toggle("sidebar-show");
//             this.setState({ showMenu: !this.state.showMenu });
//           } else {
//             document.body.classList.toggle("sidebar-hide");
//             this.setState({ showMenu: !this.state.showMenu });

//           }
//         }
//       }

//     render() {
//         const { showMenu, isMobile } = this.state;
//         console.log('showMenu: ' + showMenu + ', ' + 'isMobile:' + isMobile); 
//         return (
//             <div className={`sidebar ${showMenu && isMobile ? "show-menu" : ""}`}>
//                 <div className="sidebar-header">
//                     {/* <Link to="/pages/home" className="sidebar-logo"> <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto', alignItems: 'center' }} /> </Link>   */}
//                 </div>
//                 <div className="sidebar-body" style={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}>
//                     <SidebarMenu />
//                 </div>
//                 {isMobile && (
//                     <button className="hamburger-menu" onClick={this.toggleMenu}>
//                         <i className="ri-menu-line"></i> {/* Icono de hamburguesa */}
//                     </button>
//                 )}
//             </div>
//         )
//     }
// }

// class SidebarMenu extends Component {
//     populateMenu = (m) => {
//         const menu = m.map((m, key) => {
//             let sm;
//             if (m.submenu) {
//                 sm = m.submenu.map((sm, key) => {
//                     return (
//                         <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
//                     )
//                 })
//             }

//             return (
//                 <li key={key} className="nav-item">
//                     {(!sm) ? (
//                         <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
//                     ) : (
//                         <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
//                     )}
//                     {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//                 </li>
//             )
//         });

//         return (
//             <ul className="nav nav-sidebar">
//                 {menu}
//             </ul>
//         );
//     }

//     toggleSubMenu = (e) => {
//         e.preventDefault();
//         let parent = e.target.closest('.nav-item');
//         let node = parent.parentNode.firstChild;

//         while (node) {
//             if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
//                 node.classList.remove('show');
//             node = node.nextElementSibling || node.nextSibling;
//         }

//         parent.classList.toggle('show');
//     }
    

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Maintenances</div>
//                     {this.populateMenu(maintenance)}
//                 </div>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Transactions</div>
//                     {this.populateMenu(transactions)}
//                 </div>
//                 {/* <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Invoices</div>
//                     {this.populateMenu(invoice)}
//                 </div> */}
//                 {/* <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Settings</div>
//                     {this.populateMenu(maintenance)}
//                 </div>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>Page Munu</div>
//                     {this.populateMenu(pagesMenu)}
//                 </div>
//                 <div className="nav-group show">
//                     <div className="nav-label" onClick={this.toggleMenu}>UI Elements</div>
//                     {this.populateMenu(uiElementsMenu)}
//                 </div> */}
//             </React.Fragment>
//         )
//     }
// }
