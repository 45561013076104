import { createSlice } from '@reduxjs/toolkit';

export const tipoPagoSlice = createSlice({
    name: 'tipoPago',
    initialState: {
        tipoPagos: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setTipoPagos: (state, { payload } ) => {
            state.tipoPagos = payload;
        },
        upSertTipoPago: (state, {payload}) => {
            state.tipoPagos = payload;
        },
        setTipoPagosDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setTipoPagos,
    upSertTipoPago,
    setTipoPagosDataForm
} = tipoPagoSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 