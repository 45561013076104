import { createSlice } from '@reduxjs/toolkit';

export const cuentaBancariasSlice = createSlice({
    name: 'cuentaBancaria',
    initialState: {
        cuentasBancarias: [
            // tempEvent
        ],
        getDataForm: [],
        bancos: [],
        monedas: [],
        pais_empresa: [],
        clasifica: []
    },
    reducers: {
        setCuentasBancarias: (state, { payload } ) => {
            state.cuentasBancarias = payload;
        },
        setBancosCombo: (state, { payload } ) => {
            state.bancos = payload.bancos;
        },
        setMonedasCombo: (state, { payload } ) => {
            state.monedas = payload.monedas;
        },
        upSertCuentaBancaria: (state, {payload}) => {
            state.cuentasBancarias = payload;
        },
        setCuentaBancariaDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData.data;
        },
        setDataCombosBancoMoneda: (state, {payload}) => {
            state.bancos = payload.selects.data.banco_x_pai;
            state.monedas = payload.selects.data.moneda;
            state.pais_empresa = payload.selects.data.pais_empresa;
            state.clasifica = payload.selects.data.clasifica;
        }
    }
});


// Action creators are generated for each case reducer function
export const {
    setCuentasBancarias,
    setBancosCombo,
    setMonedasCombo,
    upSertCuentaBancaria,
    setCuentaBancariaDataForm,
    setDataCombosBancoMoneda,
} = cuentaBancariasSlice.actions;