import { createSlice } from '@reduxjs/toolkit';

export const prograReporteSlice = createSlice({
    name: 'prograReporte',
    initialState: {
        prograReportes: [
            // tempEvent
        ],
        reportes: [],
        recurrencia: [],
        getDataForm: [],
        usuarios_combo: [],
        usuarios_asignados: [],
        paisempresas_combo: [],
        paisempresas_asignados: [],
        idAsignar: [],
        
    },
    reducers: {
        setPrograReportes: (state, { payload } ) => {
            state.prograReportes = payload.prograReportes;
        },
        upSertPrograReporte: (state, {payload}) => {
            state.prograReportes = payload;
        },
        setPrograReporteDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setReportesCombo: (state, {payload}) => {
            state.reportes = payload.reportes;
        },
        setRecurrenciaCombo: (state, {payload}) => {
            state.recurrencia = payload.recurrencia;
        },
        setDataAsignacion: (state, {payload}) => {
            state.idAsignar = payload.id;
            state.usuarios_combo = payload.data.usuarios_combo;
            state.usuarios_asignados = payload.data.usuarios_asignados;
            state.paisempresas_combo = payload.data.paisempresas_combo;
            state.paisempresas_asignados = payload.data.paisempresas_asignados;
        },
        setIdAsignacion: (state, {payload}) => {
            state.idAsignar = payload.id;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setPrograReportes,
    upSertPrograReporte,
    setPrograReporteDataForm,
    setReportesCombo,
    setRecurrenciaCombo,
    setDataAsignacion,
    setIdAsignacion,
} = prograReporteSlice.actions;