import { configureStore } from '@reduxjs/toolkit';
import { 
    uiSlice, 
    calendarSlice, 
    authSlice, 
    userSlice,
    permisoSlice,
    rolSlice, 
    permisoXRolSlice, 
    solicitudSlice,
    transaccionSlice,
    tipoSolicitudSlice,
    metodoPagoSlice,
    monedaSlice,
    tipoPagoSlice,
    recurrenciaPagoSlice,
    pagoXOperativaSlice,
    solicitudOperativaSlice,
    MetPagoXOperativaSlice,
    recurrenciaXOperativaSlice,
    MonedaXOperativaSlice,
    personasConCuentaSlice,
    pagosServiciosSlice,
    configuracionPagosSlice,
    reporteSlice,
    bancoSlice,
    cuentaBancariasSlice,
    paisSlice,
    empresaSlice,
    saldosBancariosSlice,
    prograReporteSlice,
    cuentaContablesSlice,
    clasificacionesSlice,
    usuariosXCuentaSlice,

} from './';


export const store = configureStore({
    reducer: {
        auth:     authSlice.reducer,
        calendar: calendarSlice.reducer,
        ui:       uiSlice.reducer,
        user:     userSlice.reducer,
        permisos: permisoSlice.reducer,
        rol:      rolSlice.reducer,
        permisoXRol: permisoXRolSlice.reducer,
        solicitud: solicitudSlice.reducer,
        transaccion: transaccionSlice.reducer,
        tipoSolicitud: tipoSolicitudSlice.reducer,
        metodoPago: metodoPagoSlice.reducer,
        moneda: monedaSlice.reducer,
        tipoPago: tipoPagoSlice.reducer,
        recurrenciaPago: recurrenciaPagoSlice.reducer,
        pagoXOperativa: pagoXOperativaSlice.reducer,
        solicitudOperativa: solicitudOperativaSlice.reducer,
        metPagoXOperativa: MetPagoXOperativaSlice.reducer,
        recurrenciaXOperativa: recurrenciaXOperativaSlice.reducer,
        monedaXOperativa: MonedaXOperativaSlice.reducer,
        personasConCuenta: personasConCuentaSlice.reducer,
        pagoServicios: pagosServiciosSlice.reducer,
        configuracionPago: configuracionPagosSlice.reducer,
        reporte: reporteSlice.reducer,
        banco: bancoSlice.reducer,
        cuentaBancaria: cuentaBancariasSlice.reducer,
        pais: paisSlice.reducer,
        empresa: empresaSlice.reducer,
        saldoBancario: saldosBancariosSlice.reducer,
        prograReporte: prograReporteSlice.reducer,
        cuentaContable: cuentaContablesSlice.reducer,
        clasificacion: clasificacionesSlice.reducer,
        usuariosCuentas: usuariosXCuentaSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
