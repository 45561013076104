import { createSlice } from '@reduxjs/toolkit';

export const clasificacionesSlice = createSlice({
    name: 'clasificacion',
    initialState: {
        clasificaciones: [
            // tempEvent
        ],
        getDataForm: [],
    },
    reducers: {
        setClasificaciones: (state, { payload } ) => {
            state.clasificaciones = payload.data;
        },
        upSertClasificaciones: (state, {payload}) => {
            state.clasificaciones = payload;
        },
        setClasificacionDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setClasificaciones,
    upSertClasificaciones,
    setClasificacionDataForm,
} = clasificacionesSlice.actions;