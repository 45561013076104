import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Main from './layouts/Main';
import NotFound from "./views/NotFound/NotFound";

// import routes
import protectedRoutes from "./routes/ProtectedRoutes";

import { useEffect } from 'react';
import { useAuthStore } from './hooks';
//import  Signin  from './views/Signin/Signin'; 
import Signin from "./views/Signin/Signin";
import Signup from './views/Signup/Signup'; // Importa tu componente de registro




// import css
import "./styles/assets/css/remixicon.css";

// import scss
import "./styles/scss/style.scss";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {

  const { status, checkAuthToken, abrirCargar } = useAuthStore();

  useEffect(() => {
      checkAuthToken();
  }, [])

  if ( status === 'checking' ) {
    abrirCargar();
  }

  return (
    <React.Fragment>
      {/* <BrowserRouter> */}
        <Routes>
          {status === 'not-authenticated' ? (
            <>
              <Route path="/" element={<Signin />} />
              <Route path="/pages/signup" element={<Signup />} /> {/* Ruta para registro */}
              <Route path="/*" element={<Signin />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Main />}>
              <Route path="/" element={<Navigate to="/pages/home" />} />
                {protectedRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  )
                })}
              </Route>
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      {/* </BrowserRouter> */}
    </React.Fragment>
  )
}
// import React from "react";
// import { BrowserRouter, Routes, Route, Navigate, MemoryRouter } from "react-router-dom";

// import Main from './layouts/Main';
// import NotFound from "./views/NotFound/NotFound";

// // import routes
// import publicRoutes from "./routes/PublicRoutes";
// import protectedRoutes from "./routes/ProtectedRoutes";

// import { useEffect } from 'react';
// import { useAuthStore } from './hooks';
// //import  Signin  from './views/Signin/Signin'; 
// import Signin from "./views/Signin/Signin";
// import PostSignin from "./views/PostSignin/PostSignin"
// import Swal from 'sweetalert2';
// import SpinnerCarga from "./sharedComponents/SpinnerCarga";

// // import css
// import "./styles/assets/css/remixicon.css";
// import "./styles/scss/pages/_spinnerLogin.scss";

// // import scss
// import "./styles/scss/style.scss";


// // set skin on load
// window.addEventListener("load", function () {
//   let skinMode = localStorage.getItem("skin-mode");
//   let HTMLTag = document.querySelector("html");

//   if (skinMode) {
//     HTMLTag.setAttribute("data-skin", skinMode);
//   }
// });

// export default function App() {

//   const { status, checkAuthToken, abrirCargar, InicioSesioncheckAuthToken} = useAuthStore();

//   //Se obtiene de la URL los parametros start con el Token
//   //y user con el usuario AD.
//   //Estos datos son enviados desde el sistema centralizado
//   const searchParams = new URLSearchParams(document.location.search);
//   let nuevoToken = searchParams.get('start');
//   let usuarioURL = searchParams.get('user');
//   let id_Pais_Sistema = searchParams.get('system')

//   //Función para escoger que función utilizar para iniciar sesión
//   const escogerFuncionParaIniciarSesion = () => {

//     //Si los parametros de la URL están vacios entonces se hace un inicio de sesión normal
//     //para revisar el tocken actual y mostrar el formulario de inicio de sesión
//     if(nuevoToken === null || nuevoToken === '' || usuarioURL === null || usuarioURL === ''){
//       checkAuthToken();
//     }
//     /*
//     Si los parametros de la URL tienen datos, quiere decir que es un redireccionamiento desde
//     el sistema de permisos centralizado. Por lo que se utiliza una función para iniciar sesión
//     con el token y usuario AD enviados
//     */
//     else {
//       InicioSesioncheckAuthToken(nuevoToken, usuarioURL, id_Pais_Sistema);
//     }

//   }

//   //Al renderizar la pagina se usa la función para escoger el tipo de inicio de sesión
//   //Normal o redirección desde el sistema de permisos centralizados
//   useEffect(() => {
//     escogerFuncionParaIniciarSesion();
//   }, [])

//   if ( status === 'checking' ) {
//     return (
         
//       <SpinnerCarga title="Iniciando sesión..." />
//       
//       )
//   }
//   return (
//     <React.Fragment>
//      <BrowserRouter basename="/"> 
//     {/* <BrowserRouter> */}
//       <Routes>
//         {
//           ( status === 'not-authenticated')
//               ? (
//                   <>
//                   <Route path="/" element={<Signin />}></Route>
//                   <Route path="/*" element={<Signin />} />
//                   </>
//                 )
//                 : (
//                   <>
//                    <Route path="/" element={<Main />}>
//                     {protectedRoutes.map((route, index) => {
//                       return (
//                         <Route
//                           path={route.path}
//                           element={route.element}
//                           key={index}
//                         />
//                       )
//                     })}
//                 </Route>
//               </>
//             )}
//          <Route path="*" element={<NotFound />} /> 
//       </Routes>
//     </BrowserRouter>
//   </React.Fragment>
//   )
// }
