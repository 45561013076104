import { createSlice } from '@reduxjs/toolkit';

export const recurrenciaXOperativaSlice = createSlice({
    name: 'recurrenciaXOperativa',
    initialState: {
        recurrenciaXOperativas: [
            // tempEvent
        ],
        getDataForm: [],
        rcp_lista: [],    
        pae_lista: []
    },
    reducers: {
        setRecurrenciaXOperativas: (state, { payload } ) => {
            state.recurrenciaXOperativas = payload;
        },
        upSertRecurrenciaXOperativa: (state, {payload}) => {
            state.recurrenciaXOperativas = payload;
        },
        setRecurrenciaXOperativasDataForm: (state, {payload}) => {
            state.getDataForm = payload.getData;
        },
        setDataSelectsRecurrencia: (state, {payload}) => {
            state.rcp_lista = payload.selects.rcp_lista;
            state.pae_lista = payload.selects.pae_lista;
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    setRecurrenciaXOperativas,
    upSertRecurrenciaXOperativa,
    setRecurrenciaXOperativasDataForm,
    setDataSelectsRecurrencia
} = recurrenciaXOperativaSlice.actions;                                                                                                                                                                                                                                                                                                                                                                                 