import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useProductStore } from '../../hooks/Mantenimiento_Generales/useProductStore';
import Select from 'react-select';

export default function Standard() {
  const [categories, setCategories] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    description: '',
    status: 1,
    code: '',
    codeBar: '',
    price: 1,
    tax: 1,
    image: '',
    minQty: 10
  });

  const { listDataForm, Upsert } = useProductStore();
  const location = useLocation();
  const productData = location.state ? location.state.productData[0] || {} : {};

  useEffect(() => {
    getListDataForm();
  }, []);

  useEffect(() => {
    //debugger
    //getListDataForm();
    if (Object.keys(productData).length > 0) {
      setFormData({
        id: productData.id || 0,
        name: productData.name || '',
        description: productData.description || '',
        status: productData.status || 1,
        code: productData.code || '',
        codeBar: productData.code_bar || '',
        price: productData.price || 1,
        tax: productData.tax || 1,
        image: productData.image || '',
        minQty: productData.min_qty || 1
      });

      const category = categories.find(cat => cat.id === productData.category_id);
      const unitType = unitTypes.find(unit => unit.id === productData.unit_type_id);

      if (category) {
        setSelectedCategory({ value: category.id, label: category.name });
      }
      if (unitType) {
        setSelectedUnitType({ value: unitType.id, label: unitType.name });
      }
    }
  }, [productData, categories, unitTypes]);

  useEffect(() => {
    getListDataForm();
  }, []);

  const getListDataForm = async () => {
    try {
      const data = await listDataForm();
      setCategories(data.categories);
      setUnitTypes(data.unitTypes);
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    Upsert({id: formData.id, productName: formData.name, categoryId: selectedCategory.value, unitTypeId: selectedUnitType.value, productCode: formData.code, productCodeBar: formData.codeBar, productDescription: formData.description, productPrice: formData.price, productTax: formData.tax, productImage: formData.image, productMinQty: formData.minQty});
    //console.log({id: formData.id, productName: formData.name, categoryId: selectedCategory, unitTypeId: selectedUnitType, productCode: formData.code, productCodeBar: formData.codeBar, productDescription: formData.description, productPrice: formData.price, productTax: formData.tax, productImage: formData.image, productMinQty: formData.minQty});
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleUnitTypeChange = (selectedOption) => {
    setSelectedUnitType(selectedOption);
  };

  return (
    <React.Fragment>
      <Container fluid="md">
        <div className="main-app mt-0 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0">Mantenimiento Producto</h4>
            </div>
          </div>
          <Card.Body>
            <Form onSubmit={handleFormSubmit}>
              <Row className="g-4">
                <Form.Control type="hidden" name="id" value={formData.id} onChange={handleInputChange} disabled required />

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="name">Nombre</Form.Label>
                  <Form.Control type="text" placeholder="Name" name="name" value={formData.name} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="description">Descripcion</Form.Label>
                  <Form.Control type="text" placeholder="Descripcion" name="description" value={formData.description} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label>Categoria</Form.Label>
                  <Select
                    name="categoria"
                    id="id"
                    className="mb-3"
                    placeholder="Buscar"
                    onChange={handleCategoryChange}
                    options={categories.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    value={selectedCategory}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label>Unidad Tipo</Form.Label>
                  <Select
                    name="unitType"
                    id="unitType"
                    className="mb-3"
                    placeholder="Buscar"
                    onChange={handleUnitTypeChange}
                    options={unitTypes.map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                    value={selectedUnitType}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="code">Codigo</Form.Label>
                  <Form.Control type="text" placeholder="Codigo" name="code" value={formData.code} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="codeBar">Codigo Barra</Form.Label>
                  <Form.Control type="text" placeholder="Codigo Barra" name="codeBar" value={formData.codeBar} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="price">Precio</Form.Label>
                  <Form.Control type="number" placeholder="Precio" name="price" value={formData.price} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="tax">Tax</Form.Label>
                  <Form.Control type="number" placeholder="Tax" name="tax" value={formData.tax} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="image">Imagen</Form.Label>
                  <Form.Control type="text" disabled placeholder="Image" name="image" value={formData.image} onChange={handleInputChange} required />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="minQty">Cantidad Mínima Aviso <strong>(No es cantidad de producto)</strong> </Form.Label>
                  <Form.Control type="number" placeholder="Min Qty" name="minQty" value={formData.minQty} onChange={handleInputChange} required />
                </Form.Group>

                {/* <Form.Group as={Col} md="6">
                  <Form.Label>Status</Form.Label>
                  <Form.Select name="status" value={formData.status} onChange={handleInputChange} aria-label="Default select example" >
                    <option>Select</option>
                    <option value={1}>Activo</option>
                    <option value={0}>Inactivo</option>
                  </Form.Select>
                </Form.Group> */}
              </Row>
              <hr />
              <div className="text-center">
                <Button type="submit" variant="success" className="me-2">Guardar</Button>
                <Button variant="danger" as={Link} to="/pages/product">Cancelar</Button>
              </div>
            </Form>
          </Card.Body>
        </div>
      </Container>
    </React.Fragment>
  );
}
